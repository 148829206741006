import s from './Pagination.module.css'

const Pagination = ({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
  styleProps,
}) => {
  const pageNumbers = []

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i)
  }

  if (pageNumbers.length === 1) return ''

  return (
    <div className={s.pagination}>
      <div className={s.buttons}>
        {pageNumbers.map((number) => (
          <div key={number} className={s.pageItem}>
            <button
              onClick={() => paginate(number)}
              className={`${styleProps.pageLink} ${
                currentPage === number ? styleProps.active : null
              }`}
            >
              <div className={s.number}>{number}</div>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Pagination
