import s from './JoinTheTeam.module.css'
import dropdownStyles from '../../../dropdowns/FormDropdown/JoinTheTeamDropdown.module.css'
import { RightWhite, Close } from '../../../../images'
import { useHistory } from 'react-router-dom'
import FormDropdown from '../../../dropdowns/FormDropdown/FormDropdown'
import FlashMessage from '../../../LetsTalk/FlashMessage/FlashMessage'
import UploadButton from '../../../buttons/UploadFiles/UploadCV'
import SendButton from '../../../buttons/SendEmail/SendEmail'

const JoinTheTeam = (props) => {
  let history = useHistory()

  let files = []

  if (props.jobRequest.file) {
    for (var i = 0; i < props.jobRequest.file.length; i++) {
      files.push(props.jobRequest.file[i])
    }
  }

  return (
    <div className="container">
      <div className={s.joinTheTeam}>
        <div className={s.btnCircle} onClick={history.goBack}>
          <img
            src={RightWhite}
            style={{ width: 24, height: 24 }}
            alt="white arrow"
          />
        </div>
        <div className={s.title}>
          <div className={s.points}>
            <div className={s.point} />
            <div className={s.point} />
          </div>
          <span>Join the team</span>
        </div>
        <div className={s.form}>
          <div className={s.fieldCouple}>
            <div className={s.field}>
              <input
                className={props.error.isName ? s.error : ''}
                type="text"
                name="name"
                placeholder="Name"
                autoComplete="off"
                required={props.isName}
                value={props.jobRequest.name}
                onChange={(e) => props.handleChange('name', e.target.value)}
              />
              <hr className={s.hrBlack} />
              {props.jobRequest.name.length > 0 && (
                <span className={s.underMessage}>
                  Name field has to contained 2 and more symbols
                </span>
              )}
            </div>

            <div className={s.field}>
              <input
                className={props.error.isEmail ? s.error : ''}
                type="text"
                name="email"
                placeholder="Email"
                autoComplete="off"
                value={props.jobRequest.email}
                onChange={(e) => props.handleChange('email', e.target.value)}
              />
              <hr className={s.hrBlack} />
              {props.jobRequest.email.length > 0 && (
                <span className={s.underMessage}>
                  Email field has to contained "@" and "."
                </span>
              )}
            </div>
          </div>

          <div className={s.field}>
            <input
              className={props.error.isComment ? s.error : ''}
              type="text"
              name="comment"
              placeholder="Comment"
              autoComplete="off"
              value={props.jobRequest.comment}
              onChange={(e) => props.handleChange('comment', e.target.value)}
            />
            <hr className={s.hrBlack} />
            {props.jobRequest.comment.length > 0 && (
              <span className={s.underMessage}>
                The comment has to contained more than 10 and less than 300
                symbols
              </span>
            )}
          </div>
          <FormDropdown
            handleChange={props.handleChange}
            allPositions={props.allPositions}
            error={props.error}
            position={props.jobRequest.position}
            style={dropdownStyles}
          />
        </div>

        <div className={s.files}>
          {files.map((file) => (
            <div className={s.fileWrap} key={file.name}>
              <div className={s.file}>
                <span> {file.name} </span>
              </div>
              <img
                onClick={props.handleDelete(file)}
                src={Close}
                width="24"
                height="24"
                alt="close-button"
              />
            </div>
          ))}
        </div>

        <div className={s.buttons}>
          <UploadButton handleUpload={props.handleUpload}
                        text="Upload CV"
                        buttonStyle="dark"
                        variant="large"
          />
          <SendButton isLoading={props.isLoading}
                      post={props.postJobRequest}
                      variant="large"
          />
        </div>
      </div>
      {props.isFlashMessage && <FlashMessage error={props.error} />}
    </div>
  )
}

export default JoinTheTeam
