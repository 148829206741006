import s from './Reviews.module.css'
import { Left, Right } from '../../../images'
import { useState } from 'react'

const Reviews = () => {
  const [slide, setSlide] = useState(0)
  const comments = [
    {
      name: 'COO, Froth, Froth Coffee Jamie Rood',
      location: 'South Africa',
      text: '“They deliver quality services within an efficient timeline. Internal stakeholders expect the app to start generating revenue once it launches. A driven team, MileStep asks relevant questions and takes action accordingly. They are communicative and offer regular updates. ”',
    },
    {
      name: 'CTO & Founder, Bishop & Company Michael Bishop',
      location: 'St. Louis, Missouri',
      text: `“MileStep created an app that's set up my business for success. Communication is good and their code quality is fantastic. Their development principles and forward-thinking abilities are impressive.”`,
    },
    {
      name: 'Founder & CEO, B2B Fitness App',
      location: 'San Francisco, California',
      text: '“With the help of MileStep, LLC, I was able to secure funding to expand development and invest in GTM. The team was responsible and accountable. Their unwinding support and genuine guidance have also been impressive.”',
    },
    {
      name: 'CEO, Streaming Media Platform',
      location: 'Bay Area, California',
      text: '“MileStep’s assistance has sped up the business timeline, allowing for a sooner launch date. The team follows an organized, efficient, and effective workflow. So far, they’ve delivered on all of their promises.”',
    },
    {
      name: 'Founder, Social Media Platform',
      location: 'San Jose, California',
      text: 'MileStep precisely translated requirements into a sophisticated platform. Their communication, availability, and affordable pricing set them apart from others in the industry.',
    },
  ]

  const nextSlide = () => {
    if (slide < comments.length - 3) {
      setSlide(slide + 1)
    }
  }
  const prevSlide = () => {
    if (slide > 0) {
      setSlide(slide - 1)
    }
  }
  return (
    <div className={s.container}>
      <div className={s.topWrapper}>
        <div className={s.title}>
          <span>Clients about us</span>
        </div>
        <div className={s.buttons}>
          <div
            onClick={prevSlide}
            className={s.button}
            style={{ marginRight: 16 }}
          >
            <img src={Left} alt="arrow left" />
          </div>
          <div onClick={nextSlide} className={s.button}>
            <img src={Right} alt="arrow right" />
          </div>
        </div>
      </div>
      <div className={s.bottomWrapper}>
        {comments.map((comment, index) => (
          <div
            key={index}
            className={s.comment}
            style={{
              marginLeft: index === 0 ? `calc(${slide * (-100 / 3)}%)` : 0,
            }}
          >
            <div className={s.commentName}>
              <span>{comment.name}</span>
            </div>
            <div className={s.commentLocation}>
              <span>{comment.location}</span>
            </div>
            <div className={s.commentText}>
              <span>{comment.text}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Reviews
