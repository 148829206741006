import { CareersHelper } from '../helpers/careers_helper'
import Careers from '../components/Careers/Careers'

class CareersContainer extends CareersHelper {
  componentDidMount() {
    this.getJobs()
  }
  
  render() {
    return <Careers job={this.state.job} jobs={this.state.jobs} />
  }
}

export default CareersContainer
