import { Component } from 'react'
import api from './api'

export class AboutHelper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teammates: [],
        }
    }

    getTeammates() {
        api.Teammate.get().then((response) => {
            this.setState({ teammates: response.data })
        })
    }
}