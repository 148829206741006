import { ErrorImage, SuccessImage } from '../../../images'
import s from './FlashMessage.module.css'

const FlashMessage = (props) => {
  return (
    <div className={`${!props.error.request ? s.success : s.failed}`}>
      <div className="container">
        <div className={s.wrap}>
          {!props.error.request ? (
            <div className={s.row}>
              <span>Message was successfully delivered!</span>
              <img src={SuccessImage} width="24" height="24" alt="success" />
            </div>
          ) : (
            <div className={s.row}>
              <span>try again, please</span>
              <img src={ErrorImage} width="24" height="24" alt="error" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FlashMessage
