import { NavLink } from '../Link'
import s from './BurgerMenu.module.css'
import { Facebook, Git, Linkedin, Instagram, Twitter } from '../../images'

const BurgerMenu = (props) => {
  return (
    <div className={`${s.burgerMenu} ${props.isOpen ? s.active : null}`}>
      <div className={s.navItems}>
        <div className={s.navItemWrap} onClick={() => props.toggle()}>
          <NavLink
            to="/competences"
            className={s.navItem}
            activeClassName={s.activeLink}
          >
            <div className={s.navLinkWrap}>
              <span className={s.navLink}>Competences</span>
            </div>
          </NavLink>
        </div>
        <div className={s.navItemWrap} onClick={() => props.toggle()}>
          <NavLink
            to="/cases"
            className={s.navItem}
            activeClassName={s.activeLink}
          >
            <div className={s.navLinkWrap}>
              <span className={s.navLink}>Cases</span>
            </div>
          </NavLink>
        </div>
        <div className={s.navItemWrap} onClick={() => props.toggle()}>
          <NavLink
            to="/about"
            className={s.navItem}
            activeClassName={s.activeLink}
          >
            <div className={s.navLinkWrap}>
              <span className={s.navLink}>About</span>
            </div>
          </NavLink>
        </div>
        <div className={s.navItemWrap} onClick={() => props.toggle()}>
          <NavLink
            to="/careers"
            className={s.navItem}
            activeClassName={s.activeLink}
          >
            <div className={s.navLinkWrap}>
              <span className={s.navLink}>Careers</span>
            </div>
          </NavLink>
        </div>
        {/* <div className={s.navItemWrap} onClick={() => props.toggle()}>
          <a href="https://blog.milestep.io/blog" className={s.navItem}>
            <div className={s.navLinkWrap}>
              <span className={s.navLink}>Blog</span>
            </div>
          </a>
        </div> */}
        <div className={s.navItemWrap}>
          <p className={`${s.navItem} ${s.disabled}`}>
            <div className={s.navLinkWrap}>
              <span className={s.navLink}>Blog</span>
            </div>
          </p>
        </div>
        <div className={s.navItemWrap} onClick={() => props.toggle()}>
          <NavLink
            to="/contact-us"
            className={s.navItem}
            activeClassName={s.activeLink}
          >
            <div className={s.navLinkWrap}>
              <span className={s.navLink}>Contact us</span>
            </div>
          </NavLink>
        </div>
      </div>

      <div className={s.imgContainer}>
        <div className={s.imgLinks}>
          <a
            href="https://www.linkedin.com/company/milestep"
            className={s.imgCircle}
          >
            <img src={Linkedin} width="24" height="24" alt="linkedin" />
          </a>
          <a
            href="https://www.facebook.com/MileStepOutsoucing"
            className={s.imgCircle}
          >
            <img src={Facebook} width="24" height="24" alt="facebook" />
          </a>
          <a href="https://github.com/milestep" className={s.imgCircle}>
            <img src={Git} width="24" height="24" alt="git" />
          </a>
          <a
            href="https://www.instagram.com/milestep.io/"
            className={s.imgCircle}
          >
            <img src={Instagram} width="24" height="24" alt="instagram" />
          </a>
          <a href="https://twitter.com/milestepcompany" className={s.imgCircle}>
            <img src={Twitter} width="24" height="24" alt="twitter" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default BurgerMenu
