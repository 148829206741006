import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import LetsTalkContainer from '../../containers/LetsTalkContainer'
import QuestionToHrContainer from '../../containers/QuestionToHrFormContainer'
import NavigationFooter from '../NavigationFooter/NavigationFooter'

export const BottomSection = () => {
  let { pathname } = useLocation()

  const renderForm = useCallback(() => {
    if (pathname.includes('/join-the-team')) return null
    if (pathname.includes('/careers'))
      return (
        <div className="grey-section-bottom bg-red">
          <QuestionToHrContainer />
        </div>
      )
    return (
      <div className="grey-section-bottom">
        <LetsTalkContainer />
      </div>
    )
  }, [pathname])

  return (
    <>
      {renderForm()}
      <div className="grey-section-bottom no-border-top">
        <NavigationFooter />
      </div>
    </>
  )
}
