import s from './NavigationFooter.module.css'
import {
  Facebook,
  Git,
  Linkedin,
  Instagram,
  Twitter,
  Milestep,
} from '../../images'
import { Link } from '../Link'

const NavigationFooter = () => {
  return (
    <div className={s.footerContainer}>
      <div className={s.title}>
        <img src={Milestep} width="112" height="30" alt="milestep" />
      </div>
      <hr className={s.hrGrey} />
      <div className={s.info}>
        <div className={s.infoBoxes}>
          <div className={s.infoBox}>
            <span className={s.infoTitle}>Delaware</span>
            <ul>
              <li>
                <span>MileStep LLC,</span>
              </li>
              <li>
                <span>8 The Green, Suite A, Dover</span>
              </li>
              <li>
                <span>USA, Delaware, 19901</span>
              </li>
            </ul>
          </div>

          <div className={s.infoBox}>
            <span className={s.infoTitle}>Cherkasy</span>
            <ul>
              <li>
                <span>MileStep LLC,</span>
              </li>
              <li>
                <span>42/1, Priportova St.</span>
              </li>
              <li>
                <span>Cherkasy, Ukraine</span>
              </li>
            </ul>
          </div>

          <div className={s.infoBox}>
            <span className={s.infoTitle}>Uman</span>
            <ul>
              <li>
                <span>MileStep LLC,</span>
              </li>
              <li>
                <span>11, Nebesnoi Sotni St.</span>
              </li>
              <li>
                <span>Uman, Ukraine</span>
              </li>
            </ul>
          </div>

          <div className={s.infoBox}>
            <span className={s.infoTitle}>Kraków</span>
            <ul>
              <li>
                <span>MileStep LLC,</span>
              </li>
              <li>
                <span>Wadowicka 7, 30-437</span>
              </li>
              <li>
                <span>Kraków, Poland</span>
              </li>
            </ul>
          </div>

          <div className={s.infoBox}>
            <span className={s.infoTitle}>Discover Milestep</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/competences">Services</Link>
              </li>
              <li>
                <Link to="/cases">Case Studies</Link>
              </li>
              <li>
                <Link to="/about">About us</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
              {/* <li>
                <Link to="/blog">Blog</Link>
              </li> */}
            </ul>
          </div>
        </div>

        <div className={s.imgLinks}>
          <a href="https://www.linkedin.com/company/milestep">
            <img src={Linkedin} alt="linkedin" />
          </a>
          <a href="https://www.facebook.com/MileStepOutsoucing">
            <img src={Facebook} alt="facebook" />
          </a>
          <a href="https://github.com/milestep">
            <img src={Git} alt="git" />
          </a>
          <a href="https://www.instagram.com/milestep.io/">
            <img src={Instagram} alt="instagram" />
          </a>
          <a href="https://twitter.com/milestepcompany">
            <img src={Twitter} alt="twitter" />
          </a>
        </div>
      </div>
      <hr className={s.hrGrey} />
      <div className={s.bottomItems}>
        <span>All rights reserved.</span>
        <span>© Copyright 2022 Milestep</span>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <span>Privacy Policy</span>
        </Link>
      </div>
    </div>
  )
}

export default NavigationFooter
