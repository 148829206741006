import s from './Widgets.module.css'
import { G2, Gartner } from '../../../images'

const Widgets = () => {
  setTimeout(() => {
    window.GOODFIRMS.Init()
    window.CLUTCHCO.Init()
  }, 1000)
  return (
    <div className={s.wrapper}>
      <a className={s.widgetWrap} href="https://clutch.co/profile/milestep">
        <div className={s.widget}>
          <div
            className="clutch-widget"
            data-url="https://widget.clutch.co"
            data-widget-type="2"
            data-height="auto"
            data-theme="navy"
            data-clutchcompany-id="246918"
          ></div>
        </div>
      </a>

      <a
        className={s.widgetWrap}
        href="https://www.g2.com/products/milestep/reviews"
      >
        <div className={s.widget}>
          <picture>
            <img src={G2} alt="G2" />
          </picture>
        </div>
      </a>

      <div className={s.widgetWrap}>
        <div className={s.widget}>
          <picture>
            <img src={Gartner} alt="Gartner" />
          </picture>
        </div>
      </div>

      <div className={s.widgetWrap}>
        <a
          href="https://www.goodfirms.co/company/milestep"
          className={s.widget}
        >
          <div
            className="goodfirm-widget"
            data-widget-type="goodfirms-widget-t4"
            data-widget-pattern="simple-basic"
            data-height="120"
            data-company-id="19767"
          ></div>
        </a>
      </div>
    </div>
  )
}

export default Widgets
