import styled, { keyframes } from 'styled-components'
import { fadeInRight } from 'react-animations'
import { Helmet } from 'react-helmet'

import s from './About.module.css'
import AboutPromo from './AboutPromo/AboutPromo'
import ClientsThoughts from './ClientsThoughts/ClientsThoughts'
import OurBenefits from './OurBenefits/OurBenefits'
import Team from './Team/Team'

const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`
  animation: 1s ${fadeInRightAnimation};
`

const About = (props) => {
  return (
    <div className={s.about}>
      <Helmet>
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        <link rel="canonical" href="https://milestep.io/about" />
        <title>About Our Company | MileStep</title>
        <meta
          name="description"
          content="Meet Milestep team. Look inside of our values, find out more about our team, and see why do we love the things we do."
        />
        <meta property="og:type" content="company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:title" content="About Our Company | MileStep" />
        <meta property="og:url" content="https://milestep.io/about" />
        <meta property="og:site_name" content="milestep.io" />
        <meta
          property="og:description"
          content="Meet Milestep team. Look inside of our values, find out more about our team, and see why do we love the things we do."
        />
        <meta property="fb:app_id" content="147478437304935" />
        <meta property="twitter:card" content="company" />
        <meta name="twitter:site" content="milestepcompany" />
        <meta property="twitter:title" content="About Our Company | MileStep" />
        <meta
          property="twitter:description"
          content="Meet Milestep team. Look inside of our values, find out more about our team, and see why do we love the things we do."
        />
      </Helmet>
      <FadeInDiv>
        <div className="grey-section-about-promo">
          <div className="container">
            <AboutPromo />
          </div>
        </div>
      </FadeInDiv>

      <div className="container">
        <div className={s.innerContainer}>
          <OurBenefits />
        </div>
      </div>

      <div className="grey-section">
        <div className="container">
          <Team teammates={props.teammates} />
        </div>
      </div>

      <div className="container">
        <ClientsThoughts />
      </div>
    </div>
  )
}

export default About
