import HeartIcon from './hearticon'
import s from './Slides.module.css'

const Slide5 = () => {
  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.headerLine} />
        <p>milestep</p>
        <div className={s.headerLine} />
      </div>

      <div className={s.body}>
        <HeartIcon />
        <p className={s.title}>Для тих, хто шукає <br /> як доїхати?</p>
        <div className={s.column}>
          <div className={s.shortRow}>
            <span><a href="https://t.me/nampodorozi_bot">t.me/nampodorozi_bot</a>
            &nbsp;- telegram бот, який допомогає знайти попутника</span>
          </div>
          <div className={s.shortRow}>
            <span><a href="https://t.me/helpingtoleave_bot">t.me/helpingtoleave_bot</a>
            &nbsp;- чат-бот в якому люди пишуть напрямок свого руху та пропонують підвезти інших</span>
          </div>
          <div className={s.shortRow}>
            <span><a href="https://t.me/pomoc2022">t.me/pomoc2022</a>
            &nbsp;- чат, в якому можна знайти водіїв, які допоможуть виїхати закордон</span>
          </div>
          <div className={s.shortRow}>
            <span><a href="https://t.me/evacuationukraine">t.me/evacuationukraine</a>
            &nbsp;- безпечна евакуація: із міста/регіону/області/країни.</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slide5
