import React from 'react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './containers/ScrollToTop'
// import { HashRouter } from 'react-router-dom';

import { hydrate, render } from 'react-dom'

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
    hydrate(
        <BrowserRouter>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </BrowserRouter>,
        rootElement
    )
} else {
    render(
        <BrowserRouter>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </BrowserRouter>,
        rootElement
    )
}

reportWebVitals()
