import React from 'react';
import s from './Slides.module.css'
import Heart from '../../../images/heart.svg'

const HeartIcon = () => (
  <img className={s.heartIcon}
       src={Heart}
       width='50'
       height='50'
       alt='heart' />
);

export default HeartIcon;
