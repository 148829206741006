import { Component } from 'react'
import { trackForm } from './analytics_helper'
import api from './api'

const initialState = {
  jobRequest: {
    name: '',
    email: '',
    position: 'Vacancy',
    comment: '',
    file: [],
    message: '',
  },
  isFlashMessage: false,
  isLoading: false,
  done: null,
  error: {
    isName: false,
    isEmail: false,
    isPosition: false,
    isComment: false,
    request: false,
  },
  allPositions: [],
}

export class JoinTheTeamHelper extends Component {
  constructor(props) {
    super(props)
    this.state = { ...initialState }
  }

  getPositions = () => {
    api.Position.get().then((response) => {
      this.setState({ allPositions: response.data })
    })
  }

  postJobs(form) {
    this.setState({ isLoading: true })
    api.Job.post(form)
      .then(() => {
        this.setState({
          ...initialState,
          isFlashMessage: true,
          done: true,
          error: false,
        })
        setTimeout(() => {
          this.setState({
            isFlashMessage: false,
          })
        }, 3000)
        trackForm('submit_form_jointheteam_success', {
          vacancy: form.get('position'),
        })
      })
      .catch((error) => {
        this.setState({
          isFlashMessage: true,
          done: false,
          error: true,
        })
        console.log(error.message)
        trackForm('submit_form_jointheteam_error')
      })
    setTimeout(() => {
      this.setState({
        isLoading: false,
        isFlashMessage: false,
      })
    }, 3000)
  }
}
