import s from './Services.module.css'
import Card from './Card/Card'
import {
  ConsultingDesktop,
  MaintenanceAndSupportDesktop,
  MobileDevDesktop,
  QualityAssuranceDesktop,
  UIUXDesktop,
  WebDevDesktop,
} from '../../../images'

const Services = () => {
  const activeContent1 = [
    'Prototype & MVP development',
    'Website & data architecture',
    'Custom website applications',
    'Single-page applications',
    'Test-driven development',
    'Cloud & server setup',
  ]
  const activeContent2 = [
    'Cross-platform apps',
    'Hybrid applications ',
    'Complex app architecture',
    'Mobile apps optimization',
    'Deployment on app stores',
  ]
  const activeContent3 = [
    'Logo & branding',
    'Corporate identity',
    'UI/UX Design',
    'Wireframing ',
    'HTML/CSS prototype',
    'Interactive animations',
  ]
  const activeContent4 = [
    'Product discovery',
    'Product audit',
    'Project management',
    'Technical feasibility study',
    'Solutions mapping',
    'Technologies selection',
  ]
  const activeContent5 = [
    'Product audit',
    'Manual tests',
    'Functional testing',
    'Non-functional testing',
    'Unit tests',
    'Tests automation',
  ]
  const activeContent6 = [
    'L2-L3 support',
    'Code refactoring',
    'Bug fixing & troubleshooting',
    'Performance optimization',
    'Cloud & data migration',
    'Software re-engineering',
  ]

  return (
    <div className={s.services} id="services">
      <div className={s.title}>
        <span>Services</span>
      </div>
      <div className={s.cards}>
        <div className={s.card}>
          <Card
            title="Web Development"
            image={WebDevDesktop}
            items={activeContent1}
          />
        </div>
        <div className={s.card}>
          <Card
            title="Mobile Development"
            image={MobileDevDesktop}
            items={activeContent2}
          />
        </div>
        <div className={s.card}>
          <Card
            title="UI/UX Design"
            image={UIUXDesktop}
            items={activeContent3}
          />
        </div>
        <div className={s.card}>
          <Card
            title="Consulting Services"
            image={ConsultingDesktop}
            items={activeContent4}
          />
        </div>
        <div className={s.card}>
          <Card
            title="Quality Assurance"
            image={QualityAssuranceDesktop}
            items={activeContent5}
          />
        </div>
        <div className={s.card}>
          <Card
            title="Maintenance and Support"
            image={MaintenanceAndSupportDesktop}
            items={activeContent6}
          />
        </div>
      </div>
    </div>
  )
}

export default Services
