import {
  AgileDev,
  Clock,
  ComprehensiveApproach,
  DirectCommunication,
  FurtherMaintenance,
  KeepingYouPosted,
} from '../../../images'
import s from './OurApproach.module.css'

const OurApproach = () => {
  return (
    <div className={s.ourApproach}>
      <span className={s.title}>Our approach</span>
      <div className={s.infoBlocks}>
        <div className={s.infoBlockCouple}>
          <div className={s.infoBlock1}>
            <div className={s.block}>
              <img className={s.imgBlock} src={Clock} alt="clock" />
              <span className={s.titleBlockWhite}>Free project estimation</span>
              <span className={s.textBlockWhite}>
                Prior to providing our services, we thoroughly analyze your
                ideas and requirements. Then, we provide you the solution
                roadmap and estimated scope of work to guarantee we will launch
                your project in the most cost-efficient and time-effective way.
              </span>
            </div>
          </div>

          <div className={s.infoBlock2}>
            <div className={s.block}>
              <img
                className={s.imgBlock}
                src={KeepingYouPosted}
                alt="keeping u posted"
              />
              <span className={s.titleBlockBlack}>Keeping you posted</span>
              <span className={s.textBlockGray}>
                Our development process is transparent for our clients. That's
                why we provide you with reports about the work we've done as
                well as give you access to the management tools upon request so
                that you can check project status yourself at any time.
              </span>
            </div>
          </div>
        </div>
        <div className={s.infoBlockCouple}>
          <div className={s.infoBlock3}>
            <div className={s.block}>
              <img
                className={s.imgBlock}
                src={DirectCommunication}
                alt="direct communication"
              />
              <span className={s.titleBlockBlack}>Direct communication</span>
              <span className={s.textBlockGray}>
                Here, every engineer can communicate with the client directly,
                while at the same time, project managers fully engage with the
                client and the team. As a result, we hit the target and deliver
                the software our clients expect to get, regardless of the
                cooperation type
              </span>
            </div>
          </div>

          <div className={s.infoBlock4}>
            <div className={s.block}>
              <img className={s.imgBlock} src={AgileDev} alt="agile dev" />
              <span className={s.titleBlockWhite}>Agile development</span>
              <span className={s.textBlockWhite}>
                To build viable smooth-running and scalable software, we divide
                the process into sprints, overview gained results and challenges
                through daily stand-ups and systematic retrospectives, use TDD
                practices, design simple yet reliable architecture, and much
                more
              </span>
            </div>
          </div>
        </div>
        <div className={s.infoBlockCouple}>
          <div className={s.infoBlock5}>
            <div className={s.block}>
              <img
                className={s.imgBlock}
                src={ComprehensiveApproach}
                alt="comprehensive approach"
              />
              <span className={s.titleBlockBlack}>Comprehensive view</span>
              <span className={s.textBlockGray}>
                Each of the developers is good at front-end, back-end, and DBs
                development. That's why our engineers have a wider view of your
                product from the implementation of the design, down to deploying
                code on a server.
              </span>
            </div>
          </div>

          <div className={s.infoBlock6}>
            <div className={s.block}>
              <img
                className={s.imgBlock}
                src={FurtherMaintenance}
                alt="further maintenance"
              />
              <span className={s.titleBlockBlack}>Further maintenance</span>
              <span className={s.textBlockGray}>
                We take full responsibility for the code we’ve written down.
                Upon implementing your project, we will provide various types of
                support maintenance services, audit and review products for
                vulnerabilities, and extend app functionality whenever required.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurApproach
