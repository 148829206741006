import React, { useEffect, useState } from 'react'
import s from './ScrollToTopButton.module.css'
import { Right } from '../../images'

export default function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false)

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  // Set the top coordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <div className={s.scrollToTop}>
      {isVisible && (
        <div onClick={scrollToTop}>
          <div className={s.buttonContent}>
            <img className={s.btnImg} src={Right} alt="Go to top" />
            <span>upward</span>
          </div>
        </div>
      )}
    </div>
  )
}
