import HeartIcon from './hearticon'
import s from './Slides.module.css'

const Slide4 = () => {
  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.headerLine} />
        <p>milestep</p>
        <div className={s.headerLine} />
      </div>

      <div className={s.body}>
        <HeartIcon />
        <p className={s.title}>Для тих, хто шукає житло</p>
        <div className={s.column}>
          <div className={s.shortRow}>
            <span><a href="https://bit.ly/378ufkw">bit.ly/378ufkw</a>
            &nbsp;- Центр Спільних Дій – допомагає людям, що їдуть від війни в більш безпечні регіони</span>
          </div>
          <div className={s.shortRow}>
            <span><a href="https://prykhystok.in.ua/">prykhystok.in.ua</a>
            &nbsp;- портал, де можна знайти житло в будь-якому регіоні України.</span>
          </div>
          <div className={s.shortRow}>
            <span><a href="https://t.me/turbotnyk_bot">t.me/turbotnyk_bot</a>
            &nbsp;- чат-бот по наданню допомоги переселенцям</span>
          </div>
          <div className={s.shortRow}>
            <span><a href="https://ua.eu4ua.org/">ua.eu4ua.org</a>
            &nbsp;- платформа допомагає переселенцям закордоном</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slide4
