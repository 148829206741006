import s from './Promo.module.css'
import {
  SmartphoneLeft,
  SmartphoneCenter,
  LaptopRight,
  SmartphoneLeftSmall,
  SmartphoneCenterSmall,
  LaptopRightSmall,
} from '../../../images'
import styled, { keyframes } from 'styled-components'
import { fadeInDown } from 'react-animations'

const fadeInDownAnimation = keyframes`${fadeInDown}`

const FadeInDownDiv1 = styled.div`
  animation: 0.5s ${fadeInDownAnimation};
`
const FadeInDownDiv2 = styled.div`
  animation: 0.9s ${fadeInDownAnimation};
`
const FadeInDownDiv3 = styled.div`
  animation: 0.7s ${fadeInDownAnimation};
`

const Promo = () => {
  return (
    <div className={s.promo}>
      <div className={s.contentWrap}>
        <div className={s.points}>
          <FadeInDownDiv1>
            <div className={s.point} />
          </FadeInDownDiv1>

          <FadeInDownDiv2>
            <div className={s.point} />
          </FadeInDownDiv2>

          <FadeInDownDiv3>
            <div className={s.point} />
          </FadeInDownDiv3>
        </div>
        <div className={s.text}>
          <div className={s.text1}>
            <span>Remote software</span>
          </div>
          <div className={s.text2}>
            <span>development teams</span>
            <p>
              We deliver carefully designed software solutions through
              engineering, UI/UX, QA
            </p>
          </div>
        </div>
      </div>
      <picture>
        <source
          srcSet={SmartphoneLeftSmall}
          media="(max-width: 1023px)"
        ></source>
        <img className={s.promoImg1} src={SmartphoneLeft} alt="smartphone" />
      </picture>
      <picture>
        <source
          srcSet={SmartphoneCenterSmall}
          media="(max-width: 1023px)"
        ></source>
        <img className={s.promoImg2} src={SmartphoneCenter} alt="smartphone" />
      </picture>
      <picture>
        <source srcSet={LaptopRightSmall} media="(max-width: 1023px)"></source>
        <img className={s.promoImg3} src={LaptopRight} alt="laptop" />
      </picture>
    </div>
  )
}

export default Promo
