import s from './Cases.module.css'
import { ViewButtonArrow } from '../../images'
import IndustryDropdown from './IndustryDropdown/IndustryDropdown'
import CoopModelDropdown from './CoopModelDropdown/CoopModelDropdown'
import YearDropdown from './YearDropdown/YearDropdown'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import { useState, useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Cases = (props) => {
  const projectList = props.useFilteredProjects
    ? props.filteredProjects
    : props.projects

  const [mobile, setMobile] = useState()

  useEffect(() => {
    setMobile(isMobile)
  }, [setMobile])

  useEffect(() => {
    setTimeout(() => {
      const id = window.location.hash
      const search = document.getElementById(id.slice(1))
      if (search) search.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  }, [])

  return (
    <div className={s.cases}>
      <Helmet>
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        <link rel="canonical" href="https://milestep.io/case-studies" />
        <title>Case Studies | MileStep</title>
        <meta
          name="description"
          content="Discover the projects we made efforts to and the industries we've worked with to get an idea of how we can help you."
        />
        <meta property="og:type" content="company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:title" content="Case Studies | MileStep" />
        <meta property="og:url" content="https://milestep.io" />
        <meta property="og:site_name" content="milestep.io" />
        <meta
          property="og:description"
          content="Discover the projects we made efforts to and the industries we've worked with to get an idea of how we can help you."
        />
        <meta property="fb:app_id" content="147478437304935" />
        <meta property="twitter:card" content="company" />
        <meta name="twitter:site" content="milestepcompany" />
        <meta property="twitter:title" content="Case Studies | MileStep" />
        <meta
          property="twitter:description"
          content="Discover the projects we made efforts to and the industries we've worked with to get an idea of how we can help you."
        />
      </Helmet>
      <div className="container">
        <div className={s.title}>
          <p>Cases</p>
        </div>

        <div className={s.dropdowns}>
          <div className={s.dropdown}>
            <IndustryDropdown
              onChange={props.onChange}
              industryValues={props.industryValues}
            />
          </div>
          <div className={s.dropdown}>
            <CoopModelDropdown
              onChange={props.onChange}
              coopModelValues={props.coopModelValues}
            />
          </div>
          <div className={s.dropdown}>
            <YearDropdown
              onChange={props.onChange}
              yearValues={props.yearValues}
            />
          </div>
        </div>

        <div className={s.projects}>
          {projectList.map((projectList) => (
            <div
              className={s.project}
              key={projectList.id}
              id={projectList.name}
            >
              <div className={s.projectContainer}>
                <div className={s.projectInfo}>
                  <div className={s.info}>
                    {mobile ? (
                      <a href={projectList.link}>
                        <span className={s.projectTitle}>
                          {' '}
                          {projectList.title}{' '}
                        </span>
                      </a>
                    ) : (
                      <span className={s.projectTitle}>
                        {' '}
                        {projectList.title}{' '}
                      </span>
                    )}

                    <div className={s.filters}>
                      <div className={s.filterWrap}>
                        <div className={s.filter}> {projectList.industry} </div>
                      </div>
                      <div className={s.filterWrap}>
                        <div className={s.filter}>
                          {' '}
                          {projectList.cooperation_model}{' '}
                        </div>
                      </div>
                      <div className={s.filterWrap}>
                        <div className={s.filter}> {projectList.year} </div>
                      </div>
                    </div>
                  </div>

                  <a href={projectList.link} className={s.viewButton}>
                    <p>View the project</p>{' '}
                    <img
                      src={ViewButtonArrow}
                      height="16"
                      width="48"
                      alt="arrow"
                    />
                  </a>
                </div>
                <LazyLoadImage
                  className={s.projectImage}
                  src={projectList.large_picture_url.url}
                  alt="project"
                />
              </div>
              <hr className={s.line} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Cases
