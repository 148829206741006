import { Component } from 'react'
import api from './api';

export class CareersHelper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobs: [],
            job: {},
        }
    }

    getJobs() {
        api.Job.get().then((response) => {
            this.setState({ jobs: response.data })
        })
    }
}
