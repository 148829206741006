import s from './WhatWeDo.module.css'
import {
  Expertise,
  Technologies,
  Industries,
  Services,
  UpAndRight,
} from '../../../images'
import { Link } from 'react-scroll'
import styled, { keyframes } from 'styled-components'
import { fadeInRight } from 'react-animations'

const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`
  animation: 1.5s ${fadeInRightAnimation};
`

const WhatWeDo = () => {
  return (
    <div className={s.whatWeDo}>
      <div className={s.textWrap}>
        <div className={s.textContainer}>
          <div className={s.title}>
            <div className={s.points}>
              <div className={s.point} />
              <div className={s.point} />
              <div className={s.point} />
            </div>
            <p> What we do </p>
          </div>
          <span>
            Cooperation transparency, understanding of your vision and your
            end-users needs, adherence to code standards & following ongoing
            best practices - all these allows us to make your product scalable
            and highly-performant
          </span>
        </div>
      </div>

      <FadeInDiv>
        <div className={s.cards}>
          <Link to="expertise" spy={true} smooth={true}>
            <div className={s.card}>
              <div className={s.img}>
                <img src={Expertise} alt="expertise" />
              </div>
              <div className={s.arrowImg}>
                <img src={UpAndRight} alt="arrow" />
              </div>
              <div className={s.cardText}>Expertise</div>
            </div>
          </Link>

          <Link to="services" spy={true} smooth={true}>
            <div className={s.card}>
              <div className={s.img}>
                <img src={Services} alt="services" />
              </div>
              <div className={s.arrowImg}>
                <img src={UpAndRight} alt="arrow" />
              </div>
              <div className={s.cardText}>Services</div>
            </div>
          </Link>

          <Link to="technologies" spy={true} smooth={true}>
            <div className={s.card}>
              <div className={s.img}>
                <img src={Technologies} alt="technologies" />
              </div>
              <div className={s.arrowImg}>
                <img src={UpAndRight} alt="arrow" />
              </div>
              <div className={s.cardText}>Technologies</div>
            </div>
          </Link>

          <Link to="industries" spy={true} smooth={true}>
            <div className={s.card}>
              <div className={s.img}>
                <img src={Industries} alt="industries" />
              </div>
              <div className={s.arrowImg}>
                <img src={UpAndRight} alt="arrow" />
              </div>
              <div className={s.cardText}>Industries</div>
            </div>
          </Link>
        </div>
      </FadeInDiv>
    </div>
  )
}

export default WhatWeDo
