import s from './QuestionToHrForm.module.css'
import dropdownStyles from '../../dropdowns/FormDropdown/QuestionToHrDropdown.module.css'
import { CloseWhite, EmailWhite, HrImage, Telegram } from '../../../images'
import FormDropdown from '../../dropdowns/FormDropdown/FormDropdown'
import UploadButton from '../../buttons/UploadFiles/UploadCV'
import SendButton from '../../buttons/SendEmail/SendEmail'
import FlashMessage from '../../LetsTalk/FlashMessage/FlashMessage'

const QuestionForm = (props) => {
  let files = []

  if (props.questionRequest.file) {
    for (var i = 0; i < props.questionRequest.file.length; i++) {
      files.push(props.questionRequest.file[i])
    }
  }

  return (
    <div className="container">
      <div className={s.formContainer}>
        <div className={s.areaItem1}>
          <span className={s.title}>Become a part of Milestep team</span>

          <div className={s.form}>
            <div className={s.itemsCouple}>
              <div className={s.field}>
                <input
                  className={props.error.isName ? s.error : ''}
                  type="text"
                  name="name"
                  placeholder="Name"
                  autoComplete="off"
                  required={props.isName}
                  value={props.questionRequest.name}
                  onChange={(e) => props.handleChange('name', e.target.value)}
                />
                <hr />
                {props.questionRequest.name.length > 0 && (
                  <span className={s.underMessage}>
                    Name field has to contained 2 and more symbols
                  </span>
                )}
              </div>

              <div className={s.field}>
                <input
                  className={props.error.isEmail ? s.error : ''}
                  type="text"
                  name="email"
                  placeholder="Email"
                  autoComplete="off"
                  value={props.questionRequest.email}
                  onChange={(e) => props.handleChange('email', e.target.value)}
                />
                <hr />
                {props.questionRequest.email.length > 0 && (
                  <span className={s.underMessage}>
                    Email field has to contained "@" and "."
                  </span>
                )}
              </div>
            </div>

            <div className={`${s.field} ${s.message}`}>
              <input
                className={props.error.isComment ? s.error : ''}
                type="text"
                name="message"
                placeholder="Message"
                autoComplete="off"
                value={props.questionRequest.comment}
                onChange={(e) => props.handleChange('comment', e.target.value)}
              />
              <hr />
              {props.questionRequest.comment.length > 0 && (
                <span className={s.underMessage}>
                  The message has to contained more than 10 and less than 300
                  symbols
                </span>
              )}
            </div>

            <FormDropdown
              handleChange={props.handleChange}
              allPositions={props.allPositions}
              error={props.error}
              position={props.questionRequest.position}
              style={dropdownStyles}
            />

            <div className={s.files}>
              {files.map((file) => (
                <div className={s.fileWrap} key={file.name}>
                  <div className={s.file}>
                    <span> {file.name} </span>
                  </div>
                  <img
                    onClick={props.handleDelete(file)}
                    src={CloseWhite}
                    width="24"
                    height="24"
                    alt="close-button"
                  />
                </div>
              ))}
            </div>

            <div className={s.buttons}>
              <UploadButton
                handleUpload={props.handleUpload}
                text="Upload CV"
                buttonStyle="light"
              />
              <SendButton
                buttonStyle="light"
                isLoading={props.isLoading}
                post={props.postQuestionRequest}
              />
            </div>
          </div>
        </div>

        <div className={s.areaItem2}>
          <span className={s.title}>Nothing matching your skills?</span>
          <p className={s.paragraph}>
            We'd suggest staying in touch anyway. Send your CV, and we'll inform
            you about the most suitable job opportunities ASAP. If you have any
            other questions, feel free to drop us a line either via Telegram or
            by email.
          </p>
        </div>

        <div className={s.areaItem3}>
          <div className={`${s.row} ${s.info}`}>
            <img className={s.image} src={HrImage} alt="hr" />
            <div className={s.column}>
              <span className={s.subtitle}>Kseniia Chernykh</span>
              <span className={s.text}>IT Recruiter</span>

              <div className={s.row}>
                <a href="mailto:ksenia@milestep.io" className={s.link}>
                  <img src={EmailWhite} alt="email" width="20" height="20" />
                  Email
                </a>
                <a href="https://t.me/k_chernykh" className={s.link}>
                  <img src={Telegram} alt="linkedin" width="20" height="20" />
                  Telegram
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.isFlashMessage && <FlashMessage error={props.error} />}
    </div>
  )
}

export default QuestionForm
