import s from './NotFoundPage.module.css'
import { ViewButtonArrow } from '../../images'
import { NotFound } from '../../images'
import { NavLink } from '../Link'
import NavigationFooter from '../NavigationFooter/NavigationFooter'
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
  return (
    <div className={s.notFoundPage}>
      <Helmet>
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        <meta name="robots" content="noindex, nofollow" />
        <title>Page Doesn't Exist | Milestep</title>
        <meta
          name="description"
          content="Sorry, we couldn't find the page you're looking for. You can try either returning to the Home page or using the menu."
        />
      </Helmet>
      <div className={s.message}>
        <picture>
          <img src={NotFound} alt="not-found" />
        </picture>
        <p>Page Not Found</p>
        <span>
          Oops, you got lost, but we'll lead you out of this non-existent page.
          There's a better place you might go:
        </span>

        <NavLink to="/" className={s.homeLink}>
          <p>Go to main page</p> <img src={ViewButtonArrow} alt="arrow" />
        </NavLink>
      </div>

      <div className={s.footerContainer}>
        <div className={s.greySection}>
          <div className={s.container}>
            <NavigationFooter />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
