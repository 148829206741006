import LetsTalk from '../components/LetsTalk/LetsTalk'
import { findIndex, slice } from 'lodash'
import { LetsTalkHelper } from '../helpers/LetsTalk_helper'

class LetsTalkContainer extends LetsTalkHelper {
  handleChange = (inputName, value) => {
    this.setState((prevState) => ({
      contact: {
        ...prevState.contact,
        [inputName]: value,
      },
      error: {
        isPrivacy: false,
        request: false,
      },
    }))
  }

  handleUpload = (value) => {
    this.setState((prevState) => ({
      contact: {
        ...prevState.contact,
        file: [...prevState.contact.file, ...value],
      },
    }))
  }

  handleDelete = (value) => () =>
    this.setState((prevState) => {
      const { file } = prevState.contact
      const index = findIndex(file, ['name', value.name])
      const start = slice(file, 0, index)
      const end = slice(file, index + 1, file.length)
      const files = [...start, ...end]

      return { contact: { ...prevState.contact, file: files } }
    })

  postContact = () => {
    const form = new FormData()

    let error = {
      isName: false,
      isEmail: false,
      isCompany: false,
      isMessage: false,
      request: false,
    }

    const files = [...this.state.contact.file]
    const searchParams = new URLSearchParams(window.location.search)
    const utmData = {
      source: searchParams.get('utm_source'),
      campaign: searchParams.get('utm_campaign'),
    }

    if (!this.state.contact.name || this.state.contact.name.length < 2) {
      error.isName = true
    }
    if (!this.state.contact.email || !validateEmail(this.state.contact.email)) {
      error.isEmail = true
    }
    if (!this.state.contact.company) {
      error.isCompany = true
    }
    if (!this.state.contact.message || this.state.contact.message.length < 10) {
      error.isMessage = true
    }

    form.append('name', this.state.contact.name)
    form.append('email', this.state.contact.email)
    form.append('company', this.state.contact.company)
    form.append('phone_number', this.state.contact.phoneNumber)
    form.append('message', this.state.contact.message)
    form.append('nda', this.state.contact.isNDA)
    if (files) files.forEach((file) => form.append('files[]', file))
    if (utmData.source) form.append('utm_source', utmData.source)
    if (utmData.campaign) form.append('utm_campaign', utmData.campaign)

    if (
      this.state.contact.isPolicy === true &&
      error.isName === false &&
      error.isEmail === false &&
      error.isCompany === false &&
      error.isMessage === false
    ) {
      this.postContacts(form)
    } else {
      this.setState({
        error: {
          isPolicy: this.state.contact.isPolicy !== true,
          ...error,
          request: false,
        },
      })
    }
  }

  render() {
    return (
      <LetsTalk
        postContact={this.postContact}
        handleChange={this.handleChange}
        contact={this.state.contact}
        handleUpload={this.handleUpload}
        done={this.state.done}
        error={this.state.error}
        handleDelete={this.handleDelete}
        isFlashMessage={this.state.isFlashMessage}
        isLoading={this.state.isLoading}
      />
    )
  }
}

export default LetsTalkContainer

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
