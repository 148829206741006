import s from './SendEmail.module.css'
import { LargeArrowLight } from '../../../images'
import { LargeArrow } from '../../../images'

const SendButton = props => {

  return (
    <button className={`${s.button}
                     ${props.buttonStyle === "light" ? s.light : '' } 
                     ${props.variant === "large" ? s.large : ''}`}
            disabled={props.isLoading}
            onClick={props.post}>
      <span>{props.isLoading ? "Sending" : "Send"}</span>
      <img src={props.buttonStyle === "light" ? LargeArrowLight : LargeArrow } width="48" height="20" alt="large arrow" />
    </button>
  )
}

export default SendButton
