import s from './ProjectPage.module.css'
import { Left, Right } from '../../../images'
import NavigationFooter from '../../NavigationFooter/NavigationFooter'
import { Link } from 'react-router-dom'

const ProjectPage = (props) => {
  return (
    <div className={s.projectPage}>
      <div className="container">
        <div className={s.infoBlock}>
          <div className={s.textWrap}>
            <div className={s.title}>
              <span>{props.project.title}</span>
            </div>
            <div className={s.text}>
              <p>{props.project.description}</p>
            </div>
          </div>
          <div className={s.circles}>
            <div className={s.circleWrap}>
              <div className={s.circle}>
                <span>{props.project.year}</span>
              </div>
            </div>
            <div className={s.circleWrap}>
              <div className={s.circle}>
                <span>Dev: {props.project.developers}</span>
              </div>
            </div>
          </div>
        </div>

        <div className={s.filters}>
          <div className={s.filterWrap}>
            <div className={s.filter}> {props.project.industry} </div>
          </div>
          <div className={s.filterWrap}>
            <div className={s.filter}> {props.project.cooperation_model} </div>
          </div>
        </div>

        <div className={s.images}>
          <img
            src={
              props.project.first_picture_url &&
              props.project.first_picture_url.url
            }
            alt="first"
          />
          <img
            src={
              props.project.second_picture_url &&
              props.project.second_picture_url.url
            }
            alt="second"
          />
          <img
            src={
              props.project.third_picture_url &&
              props.project.third_picture_url.url
            }
            alt="third"
          />
          <img
            src={
              props.project.fourth_picture_url &&
              props.project.fourth_picture_url.url
            }
            alt="fourth"
          />
        </div>

        <div className={s.buttons}>
          {props.index > 0 ? (
            <div className={s.btnWrap}>
              <Link to={`${props.prevId}`}>
                <div
                  // onClick={}
                  className={s.button}
                >
                  <img src={Left} alt="arrow left" />
                </div>
              </Link>
              <span>previous project</span>
            </div>
          ) : (
            <div />
          )}

          {props.index < props.projects.length - 1 ? (
            <div className={s.btnWrap}>
              <span>next project</span>
              <Link to={`${props.nextId}`}>
                <div
                  // onClick={}
                  className={s.button}
                >
                  <img src={Right} alt="arrow right" />
                </div>
              </Link>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>

      <div className="grey-section-bottom">
        <div className="container">
          <NavigationFooter />
        </div>
      </div>
    </div>
  )
}

export default ProjectPage
