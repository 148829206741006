import s from './YearDropdown.module.css'
import { useState, useEffect, useRef } from 'react'
import { Plus } from '../../../images'
import { includes } from 'lodash'

// const data = [{ value: "2016", label: "2016" }, { value: "2017", label: "2017" }, { value: "2018", label: "2018" },
// { value: "2019", label: "2019" }, { value: "2020", label: "2020" }, { value: "2021", label: "2021" }];
var d = new Date()
var year = d.getFullYear()

const formateData = () => {
  var data = []
  for (var y = 2016; y <= year; y++) {
    data.push(y)
  }
  return data
}

const YearDropdown = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [items] = useState(formateData())
  const toggleDropdown = () => setOpen(!isOpen)

  const ref = useRef()
  useOnClickOutside(ref, () => setOpen(false))
  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [ref, handler])
  }

  const yearLabel = (item) => {
    if (item === year) {
      return 'earlier this year'
    } else if (item === year - 1) {
      return 'last year'
    } else return item
  }

  return (
    <div>
      {isOpen ? (
        <div className={s.dropdown} ref={ref}>
          <div className={s.dropdownHeader} onClick={toggleDropdown}>
            Year
            <div className={`${s.dropdownButton} ${isOpen && s.open}`}>
              {' '}
              <img src={Plus} alt="plus" />{' '}
            </div>
          </div>
          <div className={`${s.dropdownBody} ${isOpen && s.open}`}>
            {items.map((item, index) => (
              <label for={item}>
                <div className={s.dropdownItem}>
                  <span className={s.dropdownItemDot}> {yearLabel(item)} </span>

                  <div className={s.checkBox}>
                    <label className={s.checkBoxContainer}>
                      <input
                        name="cb"
                        id={item}
                        type="checkbox"
                        value={formateData()[index]}
                        checked={includes(
                          props.yearValues,
                          formateData()[index]
                        )}
                        onChange={(e) => props.onChange(e.target, 'yearValues')}
                      />
                      <span className={s.checkmark}></span>
                    </label>
                  </div>
                </div>
              </label>
            ))}
          </div>
        </div>
      ) : (
        <div className={s.dropdown} ref={ref}>
          <div className={s.dropdownHeader} onClick={toggleDropdown}>
            Year
            <div className={`${s.dropdownButton} ${isOpen && s.open}`}>
              {' '}
              <img src={Plus} alt="plus" />{' '}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default YearDropdown
