import JoinTheTeam from '../components/Careers/Job/JoinTheTeam/JoinTheTeam'
import { findIndex, slice } from 'lodash'
import { JoinTheTeamHelper } from '../helpers/JoinTheTeam_helper'

class JoinTheTeamContainer extends JoinTheTeamHelper {
  componentDidMount() {
    this.getPositions()
  }

  handleChange = (inputName, value) => {
    this.setState((prevState) => ({
      jobRequest: {
        ...prevState.jobRequest,
        [inputName]: value,
      },
      error: {
        request: false,
      },
    }))
  }

  handleUpload = (value) => {
    this.setState((prevState) => ({
      jobRequest: {
        ...prevState.jobRequest,
        file: [...prevState.jobRequest.file, ...value],
      },
    }))
  }

  handleDelete = (value) => () =>
    this.setState((prevState) => {
      const { file } = prevState.jobRequest

      const index = findIndex(file, ['name', value.name])

      const start = slice(file, 0, index)
      const end = slice(file, index + 1, file.length)
      const files = [...start, ...end]

      return { jobRequest: { ...prevState.jobRequest, file: files } }
    })

  postJobRequest = () => {
    const form = new FormData()

    let error = {
      isName: false,
      isEmail: false,
      isPosition: false,
      isComment: false,
      request: false,
    }

    const files = [...this.state.jobRequest.file]
    const searchParams = new URLSearchParams(window.location.search)
    const utmData = {
      source: searchParams.get('utm_source'),
      campaign: searchParams.get('utm_campaign'),
    }

    if (!this.state.jobRequest.name || this.state.jobRequest.name.length < 2) {
      error.isName = true
    }
    if (
      !this.state.jobRequest.email ||
      !validateEmail(this.state.jobRequest.email)
    ) {
      error.isEmail = true
    }
    if (this.state.jobRequest.position === 'Vacancy') {
      error.isPosition = true
    }
    if (
      !this.state.jobRequest.comment ||
      this.state.jobRequest.comment.length < 10
    ) {
      error.isComment = true
    }

    form.append('name', this.state.jobRequest.name)
    form.append('email', this.state.jobRequest.email)
    form.append('position', this.state.jobRequest.position)
    form.append('comment', this.state.jobRequest.comment)
    if (files) files.forEach((file) => form.append('files[]', file))
    if (utmData.source) form.append('utm_source', utmData.source)
    if (utmData.campaign) form.append('utm_campaign', utmData.campaign)

    if (
      error.isName === false &&
      error.isEmail === false &&
      error.isPosition === false &&
      error.isComment === false
    ) {
      this.postJobs(form)
    } else {
      this.setState({
        error: {
          ...error,
          request: false,
        },
      })
    }
  }

  render() {
    return (
      <JoinTheTeam
        postJobRequest={this.postJobRequest}
        allPositions={this.state.allPositions}
        handleChange={this.handleChange}
        jobRequest={this.state.jobRequest}
        handleUpload={this.handleUpload}
        done={this.state.done}
        error={this.state.error}
        handleDelete={this.handleDelete}
        isFlashMessage={this.state.isFlashMessage}
        isLoading={this.state.isLoading}
      />
    )
  }
}

export default JoinTheTeamContainer

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
