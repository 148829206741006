import { useState } from 'react'
import Pagination from '../../Pagination/Pagination'
import s from './Team.module.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Team = (props) => {
  const filters = ['Management', 'Development', 'Design', 'QA']
  const [activeFilter, setFilter] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  let teammatesPerPage = 8
  if (window.innerWidth < 1000) teammatesPerPage = 4
  const indexOfLastTeammate = currentPage * teammatesPerPage
  const indexOfFirstTeammate = indexOfLastTeammate - teammatesPerPage
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const filteredList = props.teammates.filter((teammate) => {
    if (!activeFilter) {
      return true
    } else {
      return activeFilter === teammate.department
    }
  })
  const currentTeammates = filteredList.slice(
    indexOfFirstTeammate,
    indexOfLastTeammate
  )

  const styleProps = {
    pageLink: s.pageLink,
    active: s.active,
  }

  return (
    <div className={s.container} id="team">
      <div className={s.title}>
        <span>Team</span>
        <span>{props.teammates.length} teammates</span>
      </div>

      <div className={s.buttonsLine}>
        <div className={s.buttons}>
          {filters.map((filter, index) => (
            <div className={s.buttonWrap} key={index}>
              <button
                className={`${s.btn} ${
                  activeFilter === filter ? s.active : null
                }`}
                onClick={() => {
                  setFilter(activeFilter === filter ? null : filter)
                  paginate(1)
                }}
              >
                <div className={s.filerRow}>{filter}</div>
              </button>
            </div>
          ))}
        </div>

        <Pagination
          itemsPerPage={teammatesPerPage}
          totalItems={filteredList.length}
          paginate={paginate}
          currentPage={currentPage}
          styleProps={styleProps}
        />
      </div>

      <div className={s.teammates}>
        {currentTeammates.map((currentTeammate, index) => (
          <div className={s.teammateWrap} key={index}>
            <div className={s.teammate}>
              <div className={s.teammateImg}>
                <LazyLoadImage
                  src={currentTeammate.avatar_url.url}
                  alt="avatar"
                />
              </div>
              <span>
                {currentTeammate.first_name} {currentTeammate.last_name}
              </span>
              <br />
              <p>{currentTeammate.position.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Team
