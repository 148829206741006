import React, { useState } from 'react'
import { NavLink, Link } from '../Link'
import { Turn as Hamburger } from 'hamburger-react'

import BurgerMenu from '../BurgerMenu/BurgerMenu'
import { Logo, Large } from '../../images'
import s from './Header.module.css'

const Header = () => {
  const [isOpen, setOpen] = useState(false)

  const handleMenuToggle = (isMenuOpen) => {
    setOpen(isMenuOpen)
    if (isMenuOpen) document.body.style.setProperty('overflow', 'hidden')
    else document.body.style.removeProperty('overflow')
  }

  return (
    <div className={s.headerWrapper}>
      <div className={s.header}>
        <div className={s.container}>
          <div className={s.logoWrap} onClick={() => handleMenuToggle(false)}>
            <Link to="/" className={s.logos}>
              <div className={s.logo}>
                <img src={Logo} width="60" height="60" alt="logo" />
              </div>
              <div className={s.large}>
                <img src={Large} width="158" height="44" alt="large logo" />
              </div>
            </Link>
          </div>

          <div className={s.navItems}>
            <NavLink
              to="/competences"
              className={s.navItem}
              activeClassName={s.active}
            >
              <span className={s.navLink}>Competences</span>
            </NavLink>
            <NavLink
              to="/cases"
              className={s.navItem}
              activeClassName={s.active}
            >
              <span className={s.navLink}>Cases</span>
            </NavLink>
            <NavLink
              to="/about"
              className={s.navItem}
              activeClassName={s.active}
            >
              <span className={s.navLink}>About</span>
            </NavLink>
            <NavLink
              to="/careers"
              className={s.navItem}
              activeClassName={s.active}
            >
              <span className={s.navLink}>Careers</span>
            </NavLink>
            <div className={`${s.navItem} ${s.disabled}`}>
              <span className={s.navLink}>Blog</span>
            </div>
            {/* <a href="https://blog.milestep.io/blog" className={s.navItem}>
              <span className={s.navLink}>Blog</span>
            </a> */}
            <NavLink
              to="/contact-us"
              className={s.navItem}
              activeClassName={s.active}
            >
              <span className={s.navLink}>Contact us</span>
            </NavLink>
          </div>

          <span className={s.brgButton}>
            <Hamburger
              className={s.brgButton}
              toggled={isOpen}
              toggle={handleMenuToggle}
              size={20}
            />
          </span>
        </div>

        <BurgerMenu isOpen={isOpen} toggle={handleMenuToggle} />
      </div>
    </div>
  )
}

export default Header
