import requestManager from './requestManager'

export default {
    Job: {
        get: () => requestManager.get('/api/careers'),
        post: (form) => requestManager.post('/api/new_request', form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },

    Project: {
        get: () => requestManager.get('/api/cases')
    },

    Contact: {
        post: (form) => requestManager.post('/api/contact', form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },

    Teammate: {
        get: () => requestManager.get('/api/team')
    },

    Position: {
        get: () => requestManager.get('/api/get_positions'),
        post: (form) => requestManager.post('/api/new_question', form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }
}