import s from './Expertise.module.css'

const Expertise = () => {
  return (
    <div className={s.expertise} id="expertise">
      <div className={s.title}>
        <p>Expertise</p>
      </div>

      <div className={s.content}>
        <div className={s.column}>
          <div className={s.columnTitleWrap}>
            <div className={s.columnTitle}>
              <span>MVP & PoC</span>
              <p>.01</p>
            </div>
          </div>
          <div className={s.textWrap}>
            <span>Project discovery & idea analysis</span>
          </div>
          <div className={s.textWrap}>
            <span>Development strategy elaboration</span>
          </div>
          <div className={s.textWrap}>
            <span>Project & team management</span>
          </div>
          <div className={s.textWrap}>
            <span>Technical feasibility validation</span>
          </div>
          <div className={s.textWrap}>
            <span>Prototype development</span>
          </div>
          <div className={s.textWrap}>
            <span>MVP development</span>
          </div>
          <div className={s.textWrap}>
            <span>Market-ready product</span>
          </div>
          <div className={s.textWrap}>
            <span>Product pivot</span>
          </div>
          <div className={s.textWrap}>
            <span>Consequential product scaling</span>
          </div>
        </div>
        <div className={s.column}>
          <div className={s.columnTitleWrap}>
            <div className={s.columnTitle}>
              <span>Product Development</span>
              <p>.02</p>
            </div>
          </div>
          <div className={s.textWrap}>
            <span>Rough scope of work estimate</span>
          </div>
          <div className={s.textWrap}>
            <span>Remote engineering team management</span>
          </div>
          <div className={s.textWrap}>
            <span>Suitable tech stack & custom product</span>
          </div>
          <div className={s.textWrap}>
            <span>Brand identity & UI/UX</span>
          </div>
          <div className={s.textWrap}>
            <span>Development & QA</span>
          </div>
          <div className={s.textWrap}>
            <span>Source code & built files delivery</span>
          </div>
          <div className={s.textWrap}>
            <span>Product Deployment on Server</span>
          </div>
          <div className={s.textWrap}>
            <span>Cloud Setup</span>
          </div>
          <div className={s.textWrap}>
            <span>Ongoing Maintenance</span>
          </div>
        </div>
        <div className={s.column}>
          <div className={s.columnTitleWrap}>
            <div className={s.columnTitle}>
              <span>Remote Teams</span>
              <p>.03</p>
            </div>
          </div>
          <div className={s.textWrap}>
            <span>Recruitment process</span>
          </div>
          <div className={s.textWrap}>
            <span>Candidates screening</span>
          </div>
          <div className={s.textWrap}>
            <span>Setup interview with suited candidates</span>
          </div>
          <div className={s.textWrap}>
            <span>Engineers onboarding</span>
          </div>
          <div className={s.textWrap}>
            <span>Hardware provisioning</span>
          </div>
          <div className={s.textWrap}>
            <span>Administration (payroll, taxes)</span>
          </div>
          <div className={s.textWrap}>
            <span>Managing legal & security- paperwork</span>
          </div>
          <div className={s.textWrap}>
            <span>Further team scaling</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Expertise
