import { useState } from 'react'

import s from './Card.module.css'
import { Plus } from '../../../../images'
import { CompetencesArrow } from '../../../../images'

const Card = (props) => {
  const [isActive, setActive] = useState(false)
  const activeCard = () => setActive(!isActive)

  return (
    <div>
      <div className={`${s.card} ${isActive && s.active}`}>
        <div className={s.cardContent}>
          <div className={s.content1}>
            <img
              className={s.cardImage}
              src={props.image}
              width="94"
              height="107"
              alt="card"
            />
            <div className={s.bottomContent}>
              <div className={s.title}>
                <span> {props.title} </span>
              </div>
            </div>
          </div>
          <div className={s.content2}>
            <div className={`${s.title} ${isActive && s.active}`}>
              <span> {props.title} </span>
            </div>
            <div className={s.activeContent}>
              {props.items.map((item) => (
                <div className={s.contentRow} key={item}>
                  <img src={CompetencesArrow} alt="arrow" />
                  <span>{item}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={s.content2}>
            <img
              className={s.cardImage}
              src={props.image}
              width="94"
              height="107"
              alt="card img"
            />
            <div className={s.bottomContent}>
              <div className={s.title}>
                <span> {props.title} </span>
              </div>
            </div>
          </div>
          <div className={s.buttonWrap}>
            <div
              className={`${s.button} ${isActive && s.active}`}
              onClick={activeCard}
            >
              {' '}
              <img src={Plus} alt="plus" />{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
