import HeartIcon from './hearticon'
import s from './Slides.module.css'

const Slide2 = () => {
  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.headerLine} />
        <p>milestep</p>
        <div className={s.headerLine} />
      </div>

      <div className={s.body}>
        <HeartIcon />
        <p className={s.title}>Потребуєте допомоги за <br /> кордоном?</p>

        <div className={s.table}>
          <div className={s.column}>
            <div className={s.row}>
              <span><a href="https://ukc.gov.ua/">ukc.gov.ua</a>
              &nbsp;- Україна</span>
            </div>
            <div className={s.row}>
              <span><a href="https://ukrainesupport.net/uk/">ukrainesupport.net/uk</a>
              &nbsp;- Польща</span>
            </div>
            <div className={s.row}>
              <span><a href="https://linktr.ee/ukrainehelpberlin">linktr.ee/ukrainehelpberlin</a>
              &nbsp;- Німеччина</span>
            </div>
            <div className={s.row}>
              <span><a href="https://dopomoha.ro/uk">dopomoha.ro/uk</a>
              &nbsp;- Румунія</span>
            </div>
          </div>
          <div className={s.column}>
            <div className={s.row}>
              <span><a href="https://uarefugees.web.app/#/">uarefugees.web.app</a>
              &nbsp;- Молдова</span>
            </div>
            <div className={s.row}>
              <span><a href="https://helsinki.hu/">helsinki.hu</a>
              &nbsp;- Угорщина</span>
            </div>
            <div className={s.row}>
              <span><a href="https://nyidanmark.dk/">nyidanmark.dk</a>
              &nbsp;- Данія</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slide2
