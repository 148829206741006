import s from './PrivacyPolicy.module.css'
import NavigationFooter from '../NavigationFooter/NavigationFooter'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = () => {
  return (
    <div className={s.privacyPolicy}>
      <Helmet>
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        <link rel="canonical" href="https://milestep.io/privacy-policy" />
        <title>Privacy Policy | MileStep</title>
        <meta
          name="description"
          content="Milestep's Privacy Policy outlines how we process, use, and protect information collected via our website."
        />
        <meta property="og:type" content="company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:title" content="Privacy Policy | MileStep" />
        <meta property="og:url" content="https://milestep.io/privacy-policy" />
        <meta property="og:site_name" content="milestep.io" />
        <meta
          property="og:description"
          content="Milestep's Privacy Policy outlines how we process, use, and protect information collected via our website."
        />
        <meta property="fb:app_id" content="147478437304935" />
        <meta property="twitter:card" content="company" />
        <meta name="twitter:site" content="milestepcompany" />
        <meta property="twitter:title" content="Privacy Policy | MileStep" />
        <meta
          property="twitter:description"
          content="Milestep's Privacy Policy outlines how we process, use, and protect information collected via our website."
        />
      </Helmet>
      <div className={s.container}>
        <div className={s.titleContainer}>
          <div className={s.title}>Privacy Policy of Milestep</div>
          <div className={s.points}>
            <div className={s.point} />
            <div className={s.point} />
            <div className={s.point} />
          </div>
        </div>
        <div className={s.textContainer}>
          <div className={s.rowWrap}>
            <div className={s.textTitle}>Introduction</div>
            <div className={s.textWrap}>
              <div className={s.text}>
                Milestep LLC (“Milestep”, “we”, “us” and “our”) operates this
                Website (https://milestep.io/, “Service”) to provide our
                visitors (“users”, “you” and “your”) with information about
                Milestep and development services we deliver. Our Privacy Policy
                (“Policy”) explains how our visitors, prospects, and customers
                can invoke their rights to receive, get access, remove or use
                their Personal Data which was submitted of their own free will.
                By visiting and using our Website you submit to the terms
                described below. Milestep retains the right to modify these
                terms of use at any point in time.
              </div>
            </div>
          </div>

          <div className={s.rowWrap}>
            <div className={s.textTitle}>Information We Collect and Use</div>
            <div className={s.textWrap}>
              <div className={s.text}>
                We gather various types of information in order to understand
                how our visitors and prospects browse the Website to provide
                relevant information about our services.
              </div>
            </div>
          </div>

          <div className={s.rowWrap}>
            <div className={s.textTitle}>Information You Submit</div>
            <div className={s.textWrap}>
              <div className={s.column}>
                <div className={s.textW600}>
                  We keep your personal information private when you contact us
                  by submitting a contact form on our website. The only
                  information we would like to know while we’re getting an
                  initial letter is the next:
                </div>

                <div className={s.values}>
                  <div className={s.value}>
                    <div className={s.valuePoint} />
                    <div className={s.text}>name;</div>
                  </div>
                  <div className={s.value}>
                    <div className={s.valuePoint} />
                    <div className={s.text}>
                      contact information (email address and/or phone number);
                    </div>
                  </div>
                  <div className={s.value}>
                    <div className={s.valuePoint} />
                    <div className={s.text}>requirement-related data;</div>
                  </div>
                  <div className={s.value}>
                    <div className={s.valuePoint} />
                    <div className={s.text}>
                      other information relevant to your offer.
                    </div>
                  </div>
                </div>

                <div className={s.column}>
                  <div className={s.interiorTitle}>Log data</div>
                  <div className={s.interiorText}>
                    Our servers log the data provided by your web browser
                    automatically. This data may include information such as
                    browser type, the pages you have visited, the time of your
                    visit and the time you spend on those pages, your computer’s
                    Internet Protocol address (IP address), and other additional
                    details related to usage data.
                  </div>
                </div>

                <div className={s.column}>
                  <div className={s.interiorTitle}>
                    Third Party Service Providers
                  </div>
                  <div className={s.interiorText}>
                    To understand the behaviour of visitors on our Website
                    better, we may use third-party services to monitor, analyze
                    and improve our Service. We track the Website traffic,
                    visitors activity, and statistics the other statistics alike
                    by Google Analytics. Likewise this web service offered by
                    Google, Inc. The received data may use to both contextualize
                    and personalize the ads within its own ad network. You can
                    read more about{' '}
                    <span className={s.redText}>
                      how Google uses the data from sites which use their
                      services.
                    </span>{' '}
                    You can install the{' '}
                    <span className={s.redText}>
                      Google Analytics Opt-out Browser Add-on following this
                      link in case you don’t want to share the information about
                      your visits activity with Google Analytics.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={s.rowWrap}>
            <div className={s.textTitle}>How We Use Your Data</div>
            <div className={s.textWrap}>
              <div className={s.textW600}>
                Milestep uses the collected data for different purposes:
              </div>
              <div className={s.values}>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    to provide the Services, provide customers care and support;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    to contact you for business purposes, according to your
                    initial request;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    to customise the Website for you, improve usability and user
                    experience on{' '}
                    <span className={s.redText}>milestep.io;</span>
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    monitoring activity on the website for our own marketing
                    purposes;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    to process a CV you have applied to Milestep;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    to recognize when visitors return to your Service;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    responding and resolving issues related to our services you
                    may have;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    to give answers to your comments or queries submitted to us
                    on the website;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    to ask for your feedback or whether you want to participate
                    in a testimonial;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    to manage the violation of our rights (e.g. content fraud
                    matters).
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={s.rowWrap}>
            <div className={s.textTitle}>How We Use Your Data</div>
            <div className={s.textWrap}>
              <div className={s.textW600}>
                Milestep isn’t interested in the business of selling your
                personal information. We may disclose your personal information
                only if such action is necessary to:
              </div>
              <div className={s.values}>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    investigate or remedy potential violations of our policies;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    to protect the rights, property, and safety of other humans;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>comply with a legal obligation</div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    prevent or investigate possible wrongdoing in connection
                    with the Service;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    protect the personal safety of users of Milestep;
                  </div>
                </div>
                <div className={s.value}>
                  <div className={s.valuePoint} />
                  <div className={s.text}>
                    comply with a legal obligation if the information is
                    permitted or required by any applicable law, rule, or
                    regulation.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={s.rowWrap}>
            <div className={s.textTitle}>Links To Other Sites</div>
            <div className={s.textWrap}>
              <div className={s.text}>
                The Blog on our Website contains links (text with hyperlinks,
                banners, or image links that refer to third-party websites) that
                aren’t operated by us. Hence, if you click on the external link,
                you will be directed to the website whose Policy is out of our
                control. Although we strive to provide you with accurate content
                on our Blog, we can assume no responsibility for the privacy
                policies or practices of third-party sites. Therefore, We
                encourage you to check the Privacy Policy of every site you
                visit.
              </div>
            </div>
          </div>

          <div className={s.rowWrap}>
            <div className={s.textTitle}>Children's Privacy</div>
            <div className={s.textWrap}>
              <div className={s.text}>
                Milestep LLC provides services for people whose age is under 18.
                We don’t intentionally collect personal information from
                individuals under the age of 18. If you’re a parent or
                caretaker, and you are aware that your Child has visited our
                Website, or provided us with Personal Data, please contact us to
                remove that information from our servers.
              </div>
            </div>
          </div>

          <div className={s.rowWrap}>
            <div className={s.textTitle}>Contact Information</div>
            <div className={s.textWrap}>
              <div className={s.text}>
                Please contact us at{' '}
                <span className={s.redText}>https://milestep.io/</span> or via
                e-mail contact@milestep.io in case you have some questions or
                concerns
              </div>
              <div className={s.textW600}>
                This policy is effective as of 20 May 2021.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.footerContainer}>
        <div className={s.greySection}>
          <div className={s.container}>
            <NavigationFooter />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
