import s from './CaseStudies.module.css'
import { UpAndRight, RightWhite } from '../../../images'
import { Link } from '../../Link'

const CaseStudies = () => {
  return (
    <div className={s.caseStudies}>
      <div className={s.topWrapper}>
        <span className={s.title}>Case Studies</span>
        <Link to="/cases" className={s.navItem}>
          <div className={s.allCases}>
            <span className={s.btnText}>All cases</span>
            <div className={s.btnCircle}>
              <img
                src={RightWhite}
                style={{ width: 24, height: 24 }}
                alt="white arrow"
              />
            </div>
          </div>
        </Link>
      </div>

      <div className={s.bottomWrapper}>
        <Link to="/cases#shift" className={s.circleWrap}>
          <div className={s.circle}>
            <span>Employment solution for current and former members</span>
            <img src={UpAndRight} alt="arrow" />
          </div>
        </Link>

        <Link to="/cases#onpodio" className={s.circleWrap}>
          <div className={s.circle}>
            <span>Platform for fitness instructors and coaches</span>
            <img src={UpAndRight} alt="arrow" />
          </div>
        </Link>

        <Link to="/cases#pushpress" className={s.circleWrap}>
          <div className={s.circle}>
            <span>Gym management platform for all types of fitness</span>
            <img src={UpAndRight} alt="arrow" />
          </div>
        </Link>

        <Link to="/cases#froth" className={s.circleWrap}>
          <div className={s.circle}>
            <span>
              App for discovering and supporting the best local coffee shops
            </span>
            <img src={UpAndRight} alt="arrow" />
          </div>
        </Link>
      </div>
    </div>
  )
}

export default CaseStudies
