import s from './Industries.module.css'

const industries = [
  {
    title: 'Healthcare',
    values: [
      'EHR & ERM Systems',
      'Patient DB Management',
      'Patient Engagement Apps',
      'mHealth Solutions',
    ],
  },
  {
    title: 'FinTech',
    values: [
      'Solutions for Fraud Prevention',
      'Trading & Exchange Platforms',
      'P2P Lending Software',
      'Investment Management Apps',
    ],
  },
  {
    title: 'eCommerce',
    values: [
      'eCommerce Platforms',
      'Trading Portals',
      'B2B & B2C Marketplaces',
      'Online Ordering Systems',
    ],
  },
  {
    title: 'Fitness & Wellness',
    values: [
      'Wellness Tracking Software',
      'Gym Management Software',
      'Workout apps',
      'Nutrition and Diet apps',
    ],
  },
  {
    title: 'eLearning',
    values: [
      'Learning Management Systems',
      'Knowledge Bases',
      'Online Course Platforms',
      'eLearning Reporting Systems',
    ],
  },
  {
    title: 'Other Solutions',
    values: [
      'Event Management Apps',
      'Streamline Media Platforms',
      'Social Media Platforms',
      'Other Custom Web & Apps Solutions',
    ],
  },
]

const Industries = () => {
  return (
    <div className={s.industries} id="industries">
      <div className={s.industriesTitle}>Industries</div>
      <div className={s.content}>
        {industries.map((industry, index) => (
          <div className={s.industry} key={index}>
            <div className={s.wrap}>
              <div className={s.title}>
                <p>{industry.title}</p>
              </div>
              <div className={s.row}>
                {industry.values.map((value, index) => (
                  <div className={s.textWrap} key={index}>
                    <div className={s.text}> {value} </div>
                    <div className={s.point}> </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Industries
