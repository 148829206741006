import { useState, useEffect, useRef } from 'react'
import Warning from '../../../images/Careers/Warning.svg'

const FormDropdown = (props) => {
  const [isOpen, setOpen] = useState(false)
  const toggleDropdown = () => setOpen(!isOpen)

  const ref = useRef()

  useOnClickOutside(ref, () => setOpen(false))
  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [ref, handler])
  }

  const handleChange = (item) => {
    props.handleChange('position', item)
    setOpen(false)
  }

  return (
    <>
      {isOpen ? (
        <div
          className={`${props.style.dropdown} ${props.style.open}`}
          ref={ref}
          onClick={toggleDropdown}
        >
          <div className={props.style.dropdownHeader}>
            {props.position}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={`${props.style.dropdownButton} ${isOpen && props.style.open}`}>
                {' '}
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className={props.style.path} d="M12 4V20" strokeWidth="2"/>
                  <path className={props.style.path} d="M20 12L4 12" strokeWidth="2"/>
                </svg>{' '}
              </div>

              {props.error.isPosition && (
                  <img src={Warning} alt="warning" width="24" height="24" />
              )}
            </div>
          </div>
          <div className={`${props.style.dropdownBody} ${isOpen && props.style.open}`}>
            {props.allPositions.map((item, index) => (
              <div
                key={index}
                className={props.style.dropdownItem}
                onClick={() => handleChange(item)}
              >
                <span className={props.style.dropdownItemDot}>{item}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className={props.style.dropdown}
          ref={ref}
        >
          <div className={props.style.dropdownHeader} onClick={toggleDropdown}>
            {props.position}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={`${props.style.dropdownButton} ${isOpen && props.style.open}`}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={props.style.path} d="M12 4V20" strokeWidth="2"/>
                <path className={props.style.path} d="M20 12L4 12" strokeWidth="2"/>
              </svg>
              </div>

              {props.error.isPosition && (
                <img src={Warning} alt="warning" width="24" height="24" />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default FormDropdown
