import styled, { keyframes } from 'styled-components'
import { fadeInRight } from 'react-animations'
import { Helmet } from 'react-helmet'

import s from './Competences.module.css'
import WhatWeDo from './WhatWeDo/WhatWeDo'
import Expertise from './Expertise/Expertise'
import Services from './Services/Services'
import Technologies from './Technologies/Technologies'
import Industries from './Industries/Industries'

const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`
  animation: 1s ${fadeInRightAnimation};
`

const Competences = () => {
  return (
    <div className={s.competences}>
      <Helmet>
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        <link rel="canonical" href="https://milestep.io/competences" />
        <title>End-to-end Software Development Services | MileStep</title>
        <meta
          name="description"
          content="Facilitating your business growth by providing full-cycle development services: scaling your team, creating performant apps from scratch, and so many more."
        />
        <meta property="og:type" content="company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta
          property="og:title"
          content="End-to-end Software Development Services | MileStep"
        />
        <meta property="og:url" content="https://milestep.io/competences" />
        <meta property="og:site_name" content="milestep.io" />
        <meta
          property="og:description"
          content="Facilitating your business growth by providing full-cycle development services: scaling your team, creating performant apps from scratch, and so many more."
        />
        <meta property="fb:app_id" content="147478437304935" />
        <meta property="twitter:card" content="company" />
        <meta name="twitter:site" content="milestepcompany" />
        <meta
          property="twitter:title"
          content="End-to-end Software Development Services | MileStep"
        />
        <meta
          property="twitter:description"
          content="Facilitating your business growth by providing full-cycle development services: scaling your team, creating performant apps from scratch, and so many more."
        />
      </Helmet>
      <div className="grey-section-promo">
        <FadeInDiv>
          <div className="container">
            <WhatWeDo />
          </div>
        </FadeInDiv>
      </div>
      <div className="container">
        <Expertise />
      </div>
      <div className="grey-section-promo">
        <div className="container">
          <Services />
        </div>
      </div>
      <div className="container">
        <Technologies />
      </div>
      <div className="container">
        <Industries />
      </div>
    </div>
  )
}

export default Competences
