import { Component } from 'react'
import api from './api';

export class ProjectsHelper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            project: {},
            nextId: null,
            prevId: null,
            index: null,
        }
    }

    getProjects() {
        api.Project.get().then((response) => {
            this.setState({ projects: response.data })
        })
    }
}