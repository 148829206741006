import s from './OurBenefits.module.css'
import { RedArrow } from '../../../images'

const OurBenefits = () => {
  return (
    <div className={s.ourBenefits} id="ourBenefits">
      <div className={s.tableWrapper}>
        <div className={s.row}>
          <div className={s.column}>
            <div className={s.middleLine}>
              <p className={s.title}>Our Mission</p>
            </div>
          </div>
          <div className={s.column}>
            <div className={s.middleLine}>
              <img src={RedArrow} alt="red arrow" />
              <p className={s.text}>
                To simplify the life of every person who uses the solutions we
                create, not to complicate it.
              </p>
            </div>
          </div>
        </div>
        <div className={s.row} style={{ paddingTop: '10%' }}>
          <div className={s.column}>
            <div className={s.middleLine}>
              <p className={s.title}>Our Vision</p>
            </div>
          </div>
          <div className={s.column}>
            <div className={s.middleLine}>
              <img src={RedArrow} alt="red arrow" />
              <p className={s.text}>
                To become the most reliable partner by providing software that
                end-users love using; our clients make a profit from; we're
                proud of.
              </p>
            </div>
          </div>
        </div>
        <div className={s.row} style={{ paddingTop: '10%' }}>
          <div className={s.column}>
            <div className={s.middleLine}>
              <p className={s.title}>Our Values</p>
            </div>
          </div>
        </div>
      </div>

      <div className={s.blocks}>
        <div className={s.block}>
          <div className={s.blockContent}>
            <p>Openness</p>
            <span>
              Every problem is absolutely soluble if everything's out in the
              open. The more details about the others’ ideas, concerns, and
              challenges every member knows, the more likely we launch a
              high-grade product that matches the end-users expectations.
            </span>
          </div>
        </div>
        <div className={s.block}>
          <div className={s.blockContent}>
            <p>Scrupulosity</p>
            <span>
              When we work on the tasks, we work properly, to our maximum
              potential. Efficiently-run products don’t appear out of anywhere,
              they have grown by investing considerable efforts of each team
              member. After all, there’s no more joyful feeling than knowing
              each product we develop making the lives of many people better.
            </span>
          </div>
        </div>
        <div className={s.block}>
          <div className={s.blockContent}>
            <p>Responsibility</p>
            <span>
              We’re held accountable for the software we release, for the
              services we provide, and for the code we write. As the company, as
              the team, as every single person.
            </span>
          </div>
        </div>
        <div className={s.block}>
          <div className={s.blockContent}>
            <p>Dedication</p>
            <span>
              We believe that impressive and remarkable software goes far beyond
              the well-running code. Therefore, we get an outside perspective on
              the app. Architecture, UI/UX, overall functionality - all to make
              your customers enjoy using your product.
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurBenefits
