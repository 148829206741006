import s from './ClientsThoughts.module.css'
import { Left, Right } from '../../../images'
import { useState } from 'react'

const ClientsThoughts = () => {
  const [slide, setSlide] = useState(0)
  const comments = [
    [
      {
        name: 'COO, Froth, Froth Coffee Jamie Rood',
        location: 'South Africa',
        text: '“They deliver quality services within an efficient timeline. Internal stakeholders expect the app to start generating revenue once it launches. A driven team, MileStep asks relevant questions and takes action accordingly. They are communicative and offer regular updates. ”',
      },
      {
        name: 'CTO & Founder, Bishop & Company Michael Bishop',
        location: 'St. Louis, Missouri',
        text: `“MileStep created an app that's set up my business for success. Communication is good and their code quality is fantastic. Their development principles and forward-thinking abilities are impressive.”`,
      },
    ],
    [
      {
        name: 'Founder & CEO, B2B Fitness App',
        location: 'San Francisco, California',
        text: '“With the help of MileStep, LLC, I was able to secure funding to expand development and invest in GTM. The team was responsible and accountable. Their unwinding support and genuine guidance have also been impressive.”',
      },
      {
        name: 'CEO, Streaming Media Platform',
        location: 'Bay Area, California',
        text: '“MileStep’s assistance has sped up the business timeline, allowing for a sooner launch date. The team follows an organized, efficient, and effective workflow. So far, they’ve delivered on all of their promises.”',
      },
    ],
    [
      {
        name: 'Founder, Social Media Platform',
        location: 'San Jose, California',
        text: 'MileStep precisely translated requirements into a sophisticated platform. Their communication, availability, and affordable pricing set them apart from others in the industry.',
      },
    ],
    // [
    //     {
    //         name: 'COO, Froth, Froth Coffee Jamie Rood South Africa',
    //         text: '“They deliver quality services within an efficient timeline. Internal stakeholders expect the app to start generating revenue once it launches. A driven team, MileStep asks relevant questions and takes action accordingly. They are communicative and offer regular updates. ”'
    //     },
    //     {
    //         name: 'CTO & Founder, Bishop & Company Michael Bishop St. Louis, Missouri',
    //         text: `“MileStep created an app that's set up my business for success. Communication is good and their code quality is fantastic. Their development principles and forward-thinking abilities are impressive. ”`
    //     }
    // ],
    // [
    //     {
    //         name: 'Founder & CEO, B2B Fitness App San Francisco, California',
    //         text: '“With the help of MileStep, LLC, I was able to secure funding to expand development and invest in GTM. The team was responsible and accountable. Their unwinding support and genuine guidance have also been impressive.”'
    //     },
    //     {
    //         name: 'CEO, Streaming Media Platform Bay Area, California',
    //         text: '“MileStep’s assistance has sped up the business timeline, allowing for a sooner launch date. The team follows an organized, efficient, and effective workflow. So far, they’ve delivered on all of their promises.”'
    //     }
    // ]
  ]

  const nextSlide = () => {
    if (slide < comments.length - 2) {
      setSlide(slide + 1)
    }
  }
  const prevSlide = () => {
    if (slide > 0) {
      setSlide(slide - 1)
    }
  }
  return (
    <div className={s.clientsThoughts} id="reviews">
      <div className={s.topWrap}>
        <div className={s.titleBox}>
          <span className={s.topTitle}>Clients' thoughts about us</span>
        </div>
        <div className={s.buttons}>
          <div
            className={s.button}
            onClick={prevSlide}
            style={{ marginRight: 16 }}
          >
            <img src={Left} alt="arrow left" />
          </div>
          <div className={s.button} onClick={nextSlide}>
            <img src={Right} alt="arrow right" />
          </div>
        </div>
      </div>

      <div className={s.content}>
        {comments.map((comment, index) => (
          <div
            key={index}
            className={s.commentCouple}
            style={{
              marginLeft: index === 0 ? `calc(${slide * (-100 / 2)}%)` : 0,
            }}
          >
            {comment.map((item, index) => (
              <div className={s.commentWrap} key={index}>
                <div className={s.comment}>
                  <span>{item.name}</span>
                  <span>{item.location}</span>
                  <span>{item.text}</span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ClientsThoughts
