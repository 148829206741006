import s from './FromConcept.module.css'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

const circleStyle1 = {
  background: '#FF655C',
}

const circleStyle2 = {
  background: '#C6CAD2',
}

const circleStyle3 = {
  background: '#FFD8D6',
}

const FromConcept = () => {
  return (
    <div className={s.fromConcept}>
      <div className={s.textWrapper}>
        <div className={s.title}>
          <span className={s.titleText}>
            From conception to full-fledged product
          </span>
        </div>

        <div className={s.paragraphs}>
          <div className={s.paragraph}>
            <span>
              At Milestep, each engineer is an individual expert in their
              technologies domain who can be easily integrated into your
              in-house team, collaborate with you and your staff closely, and
              facilitate your business growth by working on what they do the
              best.
            </span>
          </div>

          <div className={s.paragraph}>
            <span>
              Here at Milestep, we believe that every product could positively
              impact the world someday. That’s why we do not just create
              solutions that will meet your expectations but strive to
              contribute our expertise to your project at all development
              stages, and thus to make the product the one your end-users will
              enjoy using.
            </span>
          </div>
        </div>
      </div>

      <div className={s.infographics}>
        <div className={s.textWrap}>
          <div className={s.block1}>
            <div className={s.textBlock}>
              <span className={s.largeFont}>
                <div className={s.circle} style={circleStyle1} />
                <CountUp end={5} duration={4}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                + years
              </span>
              <span className={s.smallFont}>On the market</span>
            </div>
          </div>
        </div>

        <div className={s.vl}></div>

        <div className={s.textWrap}>
          <div className={s.block2}>
            <div className={s.textBlock}>
              <span className={s.largeFont}>
                <div className={s.circle} style={circleStyle2} />
                <CountUp end={96} duration={3}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                ,
                <CountUp end={8} duration={3}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                %
              </span>
              <span className={s.smallFont}>NPS rate</span>
            </div>
          </div>
        </div>

        <div className={s.vl}></div>

        <div className={s.textWrap}>
          <div className={s.block3}>
            <div className={s.textBlock}>
              <span className={s.largeFont}>
                <div className={s.circle} style={circleStyle3} />
                <CountUp end={72} duration={3}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                %
              </span>
              <span className={s.smallFont}>Senior and middle developers</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FromConcept
