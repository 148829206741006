import { Helmet } from 'react-helmet'

import s from './Home.module.css'
import Promo from './Promo/Promo'
import FromConcept from './FromConcept/FromConcept'
import CaseStudies from './CaseStudies/CaseStudies'
import OurApproach from './OurApproach/OurApproach'
import Reviews from './Reviews/Reviews'
import Widgets from './Widgets/Widgets'

const Home = () => {
  return (
    <div className={s.homeWrapper}>
      <Helmet>
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        <link rel="canonical" href="https://milestep.io" />
        <title>MileStep | Mobile & Web App Development Solutions</title>
        <meta
          name="description"
          content="MileStep is an outsourcing company that provides technical IT consultancy, UI/UX, QA, custom software development, maintenance, and remote teams services - all you need to win your end-users hearts"
        />
        <meta property="og:type" content="company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta
          property="og:title"
          content="MileStep | Mobile & Web App Development Solutions"
        />
        <meta property="og:url" content="https://milestep.io" />
        <meta property="og:site_name" content="milestep.io" />
        <meta
          property="og:description"
          content="MileStep is an outsourcing company that provides technical IT consultancy, UI/UX, QA, custom software development, maintenance, and remote teams services - all you need to achieve your business goals and win your end-users hearts."
        />
        <meta property="fb:app_id" content="147478437304935" />
        <meta property="twitter:card" content="company" />
        <meta name="twitter:site" content="milestepcompany" />
        <meta
          property="twitter:title"
          content="MileStep | Mobile & Web App Development Solutions"
        />
        <meta
          property="twitter:description"
          content="MileStep is an outsourcing company that provides technical IT consultancy, UI/UX, QA, custom software development, maintenance, and remote teams services - all you need to achieve your business goals and win your end-users hearts."
        />
        <script type="application/ld+json">
          {`
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "name": "Milestep",
                        "url": "https://milestep.io/",
                        "logo": "https://milestep.io/assets/header/logo-cc15d7eb906133504f47215fe3b232acd4256fb01ec52c58960227bf9ba1c604.png",
                        "description": "MileStep is an outsourcing company that provides technical IT consultancy, UI/UX, QA, custom software development, maintenance, and remote teams services - all you need to achieve your business goals and win your end-users hearts.",
                        "founder": "MileStep",
                        "foundingDate": "27092016",
                        "sameAs":
                            [
                                "https://www.facebook.com/MileStepOutsourcing/",
                                "https://www.linkedin.com/company/milestep/",
                                "https://twitter.com/milestepcompany",
                                "https://www.instagram.com/milestep.io/"
                            ],
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "contactType": "Customer Service",
                            "telephone": "+17042885855",
                            "contactOption": "TollFree",
                            "areaServed": ["US", "CA", "AU"],
                            "availableLanguage": "English",
                            "email": "eugene@milestep.io"
                        }
                    `}
        </script>
      </Helmet>
      <div className="grey-section-promo">
        <Promo />
      </div>
      <div className="container">
        <FromConcept />
        <CaseStudies />
        <OurApproach />
        <Reviews />
        <Widgets />
      </div>
    </div>
  )
}

export default Home
