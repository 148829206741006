import HeartIcon from './hearticon'
import s from './Slides.module.css'

const Slide3 = () => {
  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.headerLine} />
        <p>milestep</p>
        <div className={s.headerLine} />
      </div>

      <div className={s.body}>
        <HeartIcon />
        <p className={s.subtitle}>Якщо страшно та тривожно</p>
        <p className={s.title}>Безкоштовна психологічна <br /> допомога:</p>
        <div className={s.column}>
          <div className={s.shortRow}>
            <span><a href="https://tellme.com.ua/">tellme.com.ua</a>
            &nbsp;- онлайн-платформа цілодобово надає українцям психологічну допомогу</span>
          </div>
          <div className={s.shortRow}>
            <span><a href="bit.ly/34vJGm3">bit.ly/34vJGm3</a>
            &nbsp;- консультативний психологічний центр "ОбійМи"</span>
          </div>
          <div className={s.shortRow}>
            <span><a href="https://t.me/Psyforpeace_Bot">t.me/Psyforpeace_Bot</a>
            &nbsp;- чат-бот з професійними психологами</span>
          </div>
          <div className={s.shortRow}>
            <span><a href="https://t.me/yaktuzaraz">t.me/yaktuzaraz</a>
            &nbsp;- чат-бот психологічної підтримки для українців</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slide3
