import HeartIcon from './hearticon'
import s from './Slides.module.css'

const Slide1 = () => {
  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.headerLine} />
        <p>milestep</p>
        <div className={s.headerLine} />
      </div>

      <div className={s.body}>
        <HeartIcon />
        <span className={s.slide1Title}>Корисні <br /> посилання <br /> під час війни</span>
        <div className={s.blueBlur} />
        <div className={s.yellowBlur} />
      </div>
    </div>
  )
}

export default Slide1
