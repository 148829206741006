import s from './CoopModelDropdown.module.css'
import { useState, useEffect, useRef } from 'react'
import { Plus } from '../../../images'
import { includes } from 'lodash'

const data = [
  { value: 'Startup Development', label: 'Startup Development' },
  { value: 'Product Development', label: 'Product Development' },
  { value: 'Remote Teams', label: 'Remote Teams' },
]

const CoopModelDropdown = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [items] = useState(data)
  const toggleDropdown = () => setOpen(!isOpen)

  const ref = useRef()
  useOnClickOutside(ref, () => setOpen(false))
  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [ref, handler])
  }

  return (
    <div>
      {isOpen ? (
        <div className={s.dropdown} ref={ref}>
          <div className={s.dropdownHeader} onClick={toggleDropdown}>
            Cooperation Model
            <div className={`${s.dropdownButton} ${isOpen && s.open}`}>
              {' '}
              <img src={Plus} alt="plus" />{' '}
            </div>
          </div>
          <div className={`${s.dropdownBody} ${isOpen && s.open}`}>
            {items.map((item) => (
              <label for={item.label}>
                <div className={s.dropdownItem}>
                  <span className={s.dropdownItemDot}>{item.label}</span>

                  <div className={s.checkBox}>
                    <label className={s.checkBoxContainer}>
                      <input
                        name="cb"
                        id={item.label}
                        type="checkbox"
                        checked={includes(props.coopModelValues, item.value)}
                        value={item.value}
                        onChange={(e) =>
                          props.onChange(e.target, 'coopModelValues')
                        }
                      />
                      <span className={s.checkmark}></span>
                    </label>
                  </div>
                </div>
              </label>
            ))}
          </div>
        </div>
      ) : (
        <div className={s.dropdown} ref={ref}>
          <div className={s.dropdownHeader} onClick={toggleDropdown}>
            Cooperation Model
            <div className={`${s.dropdownButton} ${isOpen && s.open}`}>
              {' '}
              <img src={Plus} alt="plus" />{' '}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CoopModelDropdown
