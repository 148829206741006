import s from './Job.module.css'
import { Component } from 'react'
import axios from 'axios'
import {
  CopyLink,
  Facebook,
  Linkedin,
  RedArrow,
  WhiteRight,
} from '../../../images'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { FacebookShareButton, LinkedinShareButton } from 'react-share'

class Job extends Component {
  constructor(props) {
    super(props)
    this.state = {
      job: {},
    }
  }

  getJob(id) {
    axios
      .get(`/api/job/${id}`)
      .then((response) => {
        this.setState({ job: response.data })
      })
      .catch((error) => console.log(error.message))
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.match.params.id)) {
      return {
        id: props.match.params.id,
      }
    }
  }

  componentDidMount() {
    var { id } = this.state
    this.getJob(id)
  }

  renderArrow = (type) => {
    const { job } = this.state

    switch (type) {
      case 'requirements':
        return (
          job.requirements.length > 1 && (
            <img src={RedArrow} alt="red arrow" width="32" height="16" />
          )
        )

      case 'additional_skills':
        return (
          job.additional_skills.length > 1 && (
            <img src={RedArrow} alt="red arrow" width="32" height="16" />
          )
        )

      case 'impacts':
        return (
          job.impacts.length > 1 && (
            <img src={RedArrow} alt="red arrow" width="32" height="16" />
          )
        )

      default:
        return null
    }
  }

  render() {
    return (
      <div className={s.job}>
        <div className="container">
        <div className={s.actionWrap}>
            <div className={s.actionBlock}>
              <Link
                to={{
                  pathname: `${this.state.job.id}/join-the-team`,
                  state: { id: this.state.job.id },
                }}
                className={s.buttonWrap}
              >
                <div className={s.button}>
                  <span>apply now</span>
                  <img
                    src={WhiteRight}
                    alt="white arrow"
                    width="24"
                    height="24"
                  />
                </div>
              </Link>

              <hr className={s.hrGray} />

              <div className={s.shareRow}>
                <span>Share vacancy</span>
                <div className={s.links}>
                  <FacebookShareButton
                    url={`https://new.milestep.io/careers/${this.state.job.path}&job_id=${this.state.job.id}`}
                    hashtag={'#milestep'}
                    quote="Join the Milestep team"
                    className={s.img}
                  >
                    <img src={Facebook} alt="facebook" width="24" height="24" />
                  </FacebookShareButton>
                  <LinkedinShareButton
                    title="Milestep"
                    summary="Join the Milestep team"
                    url={`https://new.milestep.io/careers/${this.state.job.path}&job_id=${this.state.job.id}`}
                    className={s.img}
                  >
                    <img src={Linkedin} alt="linkedin" width="24" height="24" />
                  </LinkedinShareButton>
                  <img
                    className={s.img}
                    src={CopyLink}
                    alt="link"
                    width="24"
                    height="24"
                    onClick={() =>
                      navigator.clipboard.writeText(`${window.location.href}`)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={s.infoBlock}>
            <span className={s.title}>{this.state.job.title}</span>
            <span className={s.text}>{this.state.job.description}</span>
            {!isEmpty(this.state.job.requirements) ? (
              <div className={s.column}>
                <span className={s.subtitle}>Requirements</span>
                {this.state.job.requirements.map((item, index) => (
                  <div className={s.row}>
                    {(this, this.renderArrow('requirements'))}
                    {item.requirement}
                  </div>
                ))}
              </div>
            ) : null}
            {!isEmpty(this.state.job.additional_skills) ? (
              <div className={s.column}>
                <span className={s.subtitle}>Will be a plus</span>
                {this.state.job.additional_skills.map((item, index) => (
                  <div className={s.row}>
                    {(this, this.renderArrow('additional_skills'))}
                    {item.will_be_a_plus}
                  </div>
                ))}
              </div>
            ) : null}
            {!isEmpty(this.state.job.impacts) ? (
              <div className={s.column}>
                <span className={s.subtitle}>Your impact</span>
                {this.state.job.impacts.map((item, index) => (
                  <div className={s.row}>
                    {(this, this.renderArrow('impacts'))}
                    {item.impact}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default Job
