import { Component } from 'react'
import { trackForm } from './analytics_helper'
import api from './api'

const initialState = {
  contact: {
    name: '',
    email: '',
    company: '',
    phoneNumber: '',
    file: [],
    message: '',
    isPolicy: false,
    isNDA: false,
  },
  isLoading: false,
  isFlashMessage: false,
  done: null,
  error: {
    isName: false,
    isEmail: false,
    isCompany: false,
    isMessage: false,
    isPolicy: false,
    request: false,
  },
}

export class LetsTalkHelper extends Component {
  constructor(props) {
    super(props)
    this.state = { ...initialState }
  }

  postContacts(form) {
    this.setState({ isLoading: true })

    api.Contact.post(form)
      .then(() => {
        this.setState({
          ...initialState,
          isFlashMessage: true,
          done: true,
          error: false,
        })
        setTimeout(() => {
          this.setState({
            isFlashMessage: false,
          })
        }, 3000)
        trackForm('submit_form_lets_talk_success', window.location.pathname)
      })
      .catch((error) => {
        this.setState({
          isFlashMessage: true,
          done: false,
          error: true,
        })
        console.log(error.message)
        trackForm('submit_form_lets_talk_error')
      })

    setTimeout(() => {
      this.setState({
        isLoading: false,
        isFlashMessage: false,
      })
    }, 3000)
  }
}
