import { Component } from 'react'
import api from './api'

export class CasesHelper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            industryValues: [],
            coopModelValues: [],
            yearValues: [],
            filteredProjects: [],
            useFilteredProjects: false,
        }
    }

    getProjects() {
        api.Project.get().then((response) => {
            this.setState({ projects: response.data })
        })
    }
}