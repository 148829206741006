import s from './UsefulInfo.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Mousewheel, Keyboard } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

import Slide1 from './Slides/Slide1'
import Slide2 from './Slides/Slide2'
import Slide3 from './Slides/Slide3'
import Slide4 from './Slides/Slide4'
import Slide5 from './Slides/Slide5'
import Slide6 from './Slides/Slide6'
import Slide7 from './Slides/Slide7'

SwiperCore.use([Pagination, Mousewheel, Keyboard]);

const swiper_settings = {
  observeParents: true,
  observer: true,
  pagination: { clickable: true },
  mousewheel: true,
  keyboard: true,
  className: `${s.swiper}`,
  cssMode: true
}

const UsefulInfo = () => {
  return (
    <div className={s.container}>
      <Swiper {...swiper_settings}>
        <SwiperSlide className={s.swiperSlide}><Slide1 /></SwiperSlide>
        <SwiperSlide className={s.swiperSlide}><Slide2 /></SwiperSlide>
        <SwiperSlide className={s.swiperSlide}><Slide3 /></SwiperSlide>
        <SwiperSlide className={s.swiperSlide}><Slide4 /></SwiperSlide>
        <SwiperSlide className={s.swiperSlide}><Slide5 /></SwiperSlide>
        <SwiperSlide className={s.swiperSlide}><Slide6 /></SwiperSlide>
        <SwiperSlide className={s.swiperSlide}><Slide7 /></SwiperSlide>
      </Swiper>
    </div>
  )
}

export default UsefulInfo
