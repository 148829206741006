import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom'

export const Link = ({ to, ...props }) => {
  const pathname = typeof to === 'string' ? to : null

  return (
    <RouterLink
      to={{ pathname, search: window.location.search, ...to }}
      {...props}
    />
  )
}
export const NavLink = ({ to, ...props }) => (
  <RouterNavLink
    to={`${to}${window.location.search ? '/' + window.location.search : ''}`}
    {...props}
  />
)
