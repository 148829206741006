import About from '../components/About/About'
import { AboutHelper } from '../helpers/about_helper'

class AboutContainer extends AboutHelper {
  componentDidMount() {
    this.getTeammates()
  }

  render() {
    return <About teammates={this.state.teammates} />
  }
}

export default AboutContainer
