import './App.css'
import Home from './components/Home/Home'
import { Redirect, Route, Switch } from 'react-router-dom'
import Competences from './components/Competences/Competences'
import ContactUs from './components/ContactUs/ContactUs'
import Header from './components/Header/Header'
import AboutContainer from './containers/AboutContainer'
import CasesContainer from './containers/CasesContainer'
import NotFoundPage from './components/NotFoundPage/NotFoundPage'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import ProjectContainer from './containers/ProjectContainer'
import CareersContainer from './containers/CareersContainer'
import Job from './components/Careers/Job/Job'
import JoinTheTeamContainer from './containers/JoinTheTeamContainer'
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton'
import { Helmet } from 'react-helmet'
import UsefulInfo from './components/UsefulInfo/UsefulInfo'
import { BottomSection } from './components/BottomSection'

// import Blogs from './components/Blogs/Blogs'

function App() {
  return (
    <>
      <Header />
      <Helmet>
        <title>MileStep</title>
        <meta
          name="description"
          content="MileStep | Web & Mobile App Development Company"
        />
      </Helmet>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/competences" component={Competences} />
        <Route exact path="/cases" component={CasesContainer} />
        <Route exact path="/cases/project/:id" component={ProjectContainer} />
        <Route exact path="/about" component={AboutContainer} />
        <Route exact path="/careers" component={CareersContainer} />
        <Route exact path="/careers/:path/:id" component={Job} />
        <Route
          exact
          path="/careers/:path/:id/join-the-team"
          component={JoinTheTeamContainer}
        />
        {/* <Route exact path='/blogs' component={Blogs} /> */}
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/info" component={UsefulInfo} />
        {window.location.pathname.includes('mobile') && <Redirect to="/" />}
        <Route path="/" component={NotFoundPage} />
      </Switch>
      <ScrollToTopButton />
      <BottomSection />
    </>
  )
}

export default App
