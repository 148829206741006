import axios from 'axios';

const instance = axios.create({
    baseURL: window.location.origin.includes('localhost')
        ? 'http://localhost:4000'
        : 'https://new.milestep.io',
    headers: { 'Access-Control-Allow-Origin': '*' }
});

export default {
    get: (path, params = {}) => instance.get(path, {
        params: params
    }),
    post: (path, params = {}) => instance.post(path, params)
};
