import HeartIcon from './hearticon'
import s from './Slides.module.css'

const Slide7 = () => {
  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.headerLine} />
        <p>milestep</p>
        <div className={s.headerLine} />
      </div>

      <div className={s.body}>
        <HeartIcon />
        <p className={s.title}>Найбільша база <br /> з усім необхідним</p>
        <div className={s.column}>
          <div className={s.shortRow}>
            <span><a href="https://bit.ly/3t2wNJz">bit.ly/3t2wNJz</a>
            &nbsp;- оновлюванна таблиця зі списками волонтерів, транспорту, ліків, палива, продуктів, теплих речей, гуманітарної допопомоги та житла</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slide7
