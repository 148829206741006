import HeartIcon from './hearticon'
import s from './Slides.module.css'

const Slide6 = () => {
  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.headerLine} />
        <p>milestep</p>
        <div className={s.headerLine} />
      </div>

      <div className={s.body}>
        <HeartIcon />
        <p className={s.title}>Діабетикам</p>
        <div className={s.column}>
          <div className={s.shortRow}>
            <span><a href="https://bit.ly/3KBCVye">bit.ly/3KBCVye</a>
            &nbsp;- таблиця зі списком аптек, де є в наявності дорослий та дитячий інсулін</span>
          </div>
          <div className={s.shortRow}>
            <span><a href="https://t.me/diapidtrymka">t.me/diapidtrymka</a>
            &nbsp;- обмін діабетичними препаратами по Україні</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slide6
