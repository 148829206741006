import Cases from '../components/Cases/Cases'
import { filter, includes } from 'lodash'
import { CasesHelper } from '../helpers/casses_helper'

class CasesContainer extends CasesHelper {
  componentDidMount() {
    this.getProjects()
  }

  static getDerivedStateFromProps(props, state) {
    const list1 = (projects, industryValues, coopModelValues, yearValues) => {
      let filteredList = state.projects
      if (industryValues && industryValues.length > 0)
        filteredList = filteredList.filter((el) =>
          industryValues.includes(el.industry)
        )
      if (coopModelValues && coopModelValues.length > 0)
        filteredList = filteredList.filter((el) =>
          coopModelValues.includes(el.cooperation_model)
        )
      if (yearValues && yearValues.length > 0)
        filteredList = filteredList.filter((el) => yearValues.includes(el.year))

      return filteredList
    }

    return {
      filteredProjects: list1(
        state.projects,
        state.industryValues,
        state.coopModelValues,
        state.yearValues
      ),
    }
  }

  onChange = (option, fieldName) => {
    includes(
      this.state[fieldName],
      fieldName === 'yearValues' ? parseInt(option.value) : option.value
    )
      ? this.handleDeleteValue(option, fieldName)
      : this.handleAddValue(option, fieldName)
  }

  handleAddValue = (option, fieldName) => {
    this.setState((prevState) => ({
      [fieldName]: [
        ...prevState[fieldName],
        fieldName === 'yearValues' ? parseInt(option.value) : option.value,
      ],
      useFilteredProjects: true,
    }))
  }

  handleDeleteValue = (option, fieldName) => {
    this.setState((prevState) => ({
      [fieldName]: filter(prevState[fieldName], (value) => {
        return (
          value !==
          (fieldName === 'yearValues' ? parseInt(option.value) : option.value)
        )
      }),
    }))
  }

  render() {
    return (
      <Cases
        projects={this.state.projects}
        onChange={this.onChange}
        industryValues={this.state.industryValues}
        coopModelValues={this.state.coopModelValues}
        yearValues={this.state.yearValues}
        filteredProjects={this.state.filteredProjects}
        useFilteredProjects={this.state.useFilteredProjects}
      />
    )
  }
}

export default CasesContainer
