import FlashMessage from './FlashMessage/FlashMessage'
import UploadButton from '../buttons/UploadFiles/UploadCV'
import SendButton from '../buttons/SendEmail/SendEmail'
import s from './LetsTalk.module.css'
import { Close, MailOutline, CallOutline, Director } from '../../images'

const LetsTalk = (props) => {
  let files = []

  if (props.contact.file) {
    for (var i = 0; i < props.contact.file.length; i++) {
      files.push(props.contact.file[i])
    }
  }

  return (
    <div className="container">
      <div className={s.container}>
        <div className={s.leftside}>
          <h2 className={s.title}>Let's talk about your project</h2>
          <div className={s.wrap}>
            <div className={s.left}>
              <div className={s.form}>
                <div className={s.fieldCouple}>
                  <div className={s.field} style={{ marginRight: '10px' }}>
                    <input
                      className={props.error.isName ? s.error : ''}
                      type="text"
                      name="name"
                      placeholder="Name"
                      autoComplete="off"
                      required={props.isName}
                      value={props.contact.name}
                      onChange={(e) =>
                        props.handleChange('name', e.target.value)
                      }
                    />
                    <hr className={s.hrBlack} />
                    {props.contact.name.length > 0 && (
                      <span className={s.underMessage}>
                        Name field has to contained 2 and more symbols
                      </span>
                    )}
                  </div>

                  <div className={s.field}>
                    <input
                      className={props.error.isEmail ? s.error : ''}
                      type="text"
                      name="email"
                      placeholder="Email"
                      autoComplete="off"
                      value={props.contact.email}
                      onChange={(e) =>
                        props.handleChange('email', e.target.value)
                      }
                    />
                    <hr className={s.hrBlack} />
                    {props.contact.email.length > 0 && (
                      <span className={s.underMessage}>
                        Email field has to contained "@" and "."
                      </span>
                    )}
                  </div>
                </div>

                <div className={s.fieldCouple}>
                  <div className={s.field} style={{ marginRight: '10px' }}>
                    <input
                      className={props.error.isCompany ? s.error : ''}
                      type="text"
                      name="company"
                      placeholder="Company"
                      autoComplete="off"
                      value={props.contact.company}
                      onChange={(e) =>
                        props.handleChange('company', e.target.value)
                      }
                    />
                    <hr className={s.hrBlack} />
                  </div>

                  <div className={s.field}>
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone number"
                      autoComplete="off"
                      value={props.contact.phoneNumber}
                      onChange={(e) =>
                        props.handleChange('phoneNumber', e.target.value)
                      }
                    />
                    <hr className={s.hrBlack} />
                  </div>
                </div>

                <div className={s.field}>
                  <textarea
                    className={props.error.isMessage ? s.error : ''}
                    name="message"
                    placeholder="Message"
                    autoComplete="off"
                    value={props.contact.message}
                    onChange={(e) =>
                      props.handleChange('message', e.target.value)
                    }
                  />
                  <hr className={s.hrBlack} />
                  {props.contact.message.length > 0 && (
                    <span className={s.underMessage}>
                      The message has to contained more than 10 symbols
                    </span>
                  )}
                </div>
              </div>

              <div className={s.checkBoxes}>
                <div className={s.checkBox}>
                  <label
                    className={s.checkBoxContainer}
                    style={{ paddingRight: 10 }}
                  >
                    I want to sign an NDA before providing project details
                    <input
                      type="checkbox"
                      checked={props.contact.isNDA}
                      onChange={(e) =>
                        props.handleChange('isNDA', e.target.checked)
                      }
                    />
                    <span className={s.checkmark}></span>
                  </label>
                </div>

                <div className={s.vl}></div>

                <div
                  className={`${s.checkBox} ${
                    props.error.isPolicy ? s.errorCheckBox : ''
                  }`}
                >
                  <label
                    className={s.checkBoxContainer}
                    style={{ paddingRight: 10 }}
                  >
                    I accept Milestep’s Privacy Policy
                    <input
                      type="checkbox"
                      checked={props.contact.isPolicy}
                      onChange={(e) =>
                        props.handleChange('isPolicy', e.target.checked)
                      }
                    />
                    <span className={s.checkmark}></span>
                  </label>
                </div>
              </div>

              <div className={s.files}>
                {files.map((file) => (
                  <div className={s.fileWrap} key={file.name}>
                    <div className={s.file}>
                      <span> {file.name} </span>
                    </div>
                    <img
                      onClick={props.handleDelete(file)}
                      src={Close}
                      width="24"
                      height="24"
                      alt="close-button"
                    />
                  </div>
                ))}
              </div>

              <div className={s.buttons}>
                <UploadButton
                  handleUpload={props.handleUpload}
                  text="Attach the files"
                  buttonStyle="dark"
                  variant="large"
                />
                <SendButton
                  buttonStyle="dark"
                  isLoading={props.isLoading}
                  post={props.postContact}
                  variant="large"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className={s.rightside}> */}
        <div className={s.rightsideTop}>
          <h2 className={s.title}>Contacts</h2>
          <p className={s.text}>
            We're open to discussing your ideas. Drop us a line about your needs
            or send the project requirements (tech stack, the scope of work,
            timeframes, business idea) so that we could provide you with the
            cooperation proposal
          </p>
        </div>
        <div className={s.rightsideBottom}>
          <div className={s.repBlock}>
            <img
              className={s.repPhoto}
              src={Director}
              alt="Eugene Korpan, CEO"
            />
            <div className={s.repInfo}>
              <p className={s.repName}>EUGENE KORPAN</p>
              <p className={s.repPosition}>CEO</p>
              <div className={s.repContact}>
                <img src={MailOutline} width="20" height="20" alt="mail" />
                <a href="mailto:eugene@milestep.io" className={s.link}>
                  eugene@milestep.io
                </a>
              </div>
              <div className={s.repContact}>
                <img src={CallOutline} width="20" height="20" alt="phone" />
                <a href="tel:+1 (704) 288-5855" className={s.link}>
                  +1 (704) 288-5855
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}

        {props.isFlashMessage && <FlashMessage error={props.error} />}
      </div>
    </div>
  )
}

export default LetsTalk
