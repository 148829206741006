import ProjectPage from '../components/Cases/ProjectPage/ProjectPage'
import { findIndex } from 'lodash'
import { ProjectsHelper } from '../helpers/projects_helper'

class ProjectContainer extends ProjectsHelper {
  componentDidMount() {
    this.getProjects()
  }
  
  static getDerivedStateFromProps(props, state) {
    let nextId = null
    let prevId = null
    let project = {}

    const index = findIndex(state.projects, {
      id: parseInt(props.match.params.id),
    })

    if (index >= 0) {
      if (index < state.projects.length - 1) {
        nextId = state.projects[index + 1].id
      }

      if (index > 0) {
        prevId = state.projects[index - 1].id
      }

      project = state.projects[index]
    }

    return { project, index, nextId, prevId }
  }

  render() {
    return (
      <ProjectPage
        project={this.state.project}
        projects={this.state.projects}
        index={this.state.index}
        nextId={this.state.nextId}
        prevId={this.state.prevId}
      />
    )
  }
}

export default ProjectContainer
