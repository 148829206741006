import {
  BackEnd,
  Databases,
  DevOps,
  FrontEnd,
  Infrastructure,
  Mobile,
} from '../../../images'
import s from './Technologies.module.css'

const Technologies = () => {
  return (
    <div className={s.technologies} id="technologies">
      <div className={s.technologiesTitle}>Technologies</div>
      <div className={s.content}>
        <div className={s.row}>
          <div className={s.area}>
            <div className={s.wrap}>
              <div className={s.imgBorderWrap}>
                {' '}
                <img src={FrontEnd} alt="front-end" />
              </div>
            </div>
            <div className={s.titleBorderWrap}>Front-End</div>
          </div>
          <div className={s.technology}>
            <div className={s.wrap}>
              <div className={s.borderWrap}>HTML</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>CSS</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>React.js</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Vue.js</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Angular</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>TypeScript</div>
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.area}>
            <div className={s.wrap}>
              <div className={s.imgBorderWrap}>
                {' '}
                <img src={BackEnd} alt="back-end" />{' '}
              </div>
            </div>
            <div className={s.titleBorderWrap}>Back-End</div>
          </div>
          <div className={s.technology}>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Ruby on Rails</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Node.js</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>GraphQL</div>
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.area}>
            <div className={s.wrap}>
              <div className={s.imgBorderWrap}>
                {' '}
                <img src={Databases} alt="databases" />{' '}
              </div>
            </div>
            <div className={s.titleBorderWrap}>Databases</div>
          </div>
          <div className={s.technology}>
            <div className={s.wrap}>
              <div className={s.borderWrap}>PostgreSQL</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>MySQL</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Elastic Search</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>MongoDB</div>
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.area}>
            <div className={s.wrap}>
              <div className={s.imgBorderWrap}>
                {' '}
                <img src={Mobile} alt="mobile" />{' '}
              </div>
            </div>
            <div className={s.titleBorderWrap}>Mobile</div>
          </div>
          <div className={s.technology}>
            <div className={s.wrap}>
              <div className={s.borderWrap}>ReactNative</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Flutter</div>
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.area}>
            <div className={s.wrap}>
              <div className={s.imgBorderWrap}>
                {' '}
                <img src={Infrastructure} alt="infrastructure" />{' '}
              </div>
            </div>
            <div className={s.titleBorderWrap}>Infrastructure</div>
          </div>
          <div className={s.technology}>
            <div className={s.wrap}>
              <div className={s.borderWrap}>AWS</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Google Cloud</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Digital Ocean</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Heroku</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Firebase</div>
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.area}>
            <div className={s.wrap}>
              <div className={s.imgBorderWrap}>
                {' '}
                <img src={DevOps} alt="dev-ops" />{' '}
              </div>
            </div>
            <div className={s.titleBorderWrap}>DevOps</div>
          </div>
          <div className={s.technology}>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Docker</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Gitlab</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Github</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Bitbucket</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Travis CI</div>
            </div>
            <div className={s.wrap}>
              <div className={s.borderWrap}>Jenkins</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Technologies
