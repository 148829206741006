import s from './Careers.module.css'
import { UpAndRight } from '../../images'
import styled, { keyframes } from 'styled-components'
import { slideInRight } from 'react-animations'
import { Helmet } from 'react-helmet'
import { Link } from '../Link'
import { useState } from 'react'
import Pagination from '../Pagination/Pagination'

const zoomInAnimation = keyframes`${slideInRight}`

const SlideIn = styled.div`
  animation: ${(props) => props.duration} ${zoomInAnimation};
`

const Careers = (props) => {
  const filters = ['Development', 'Operation', 'Marketing', 'Design']
  const [activeFilter, setFilter] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  let jobsPerPage = 4
  const indexOfLastJob = currentPage * jobsPerPage
  const indexOfFirstJob = indexOfLastJob - jobsPerPage
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const filteredList = props.jobs.filter((job) => {
    if (!activeFilter) {
      return true
    } else {
      return activeFilter === job.position_filter
    }
  })
  const currentJobs = filteredList.slice(indexOfFirstJob, indexOfLastJob)

  const styleProps = {
    pageLink: s.pageLink,
    active: s.active,
  }

  return (
    <div className={s.careers}>
      <Helmet>
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        <link rel="canonical" href="https://milestep.io/careers" />
        <title>Careers | MileStep</title>
        <meta
          name="description"
          content="Become a part of Milestep. Check available job openings, apply your CV, and climb up the career ladder with us."
        />
        <meta property="og:type" content="company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:title" content="Careers | MileStep" />
        <meta property="og:url" content="https://milestep.io/careers" />
        <meta property="og:site_name" content="milestep.io" />
        <meta
          property="og:description"
          content="Become a part of Milestep. Check available job openings, apply your CV, and climb up the career ladder with us."
        />
        <meta property="fb:app_id" content="147478437304935" />
        <meta property="twitter:card" content="company" />
        <meta name="twitter:site" content="milestepcompany" />
        <meta property="twitter:title" content="Careers | MileStep" />
        <meta
          property="twitter:description"
          content="Become a part of Milestep. Check available job openings, apply your CV, and climb up the career ladder with us."
        />
      </Helmet>
      <div className="container">
        <div className={s.topWrap}>
          <div className={s.textWrap}>
            <span className={s.titleText}>Careers</span>
            <div className={s.text}>
              <span>
                We have neither hierarchy nor bureaucracy because our
                cooperation is based on responsibility and openness. We believe
                that soft skills and dedication values more than a technical
                background. We create an environment that helps your
                professional growth, and we know that like-minded colleagues
                will facilitate you to reach the peaks faster.
              </span>
            </div>
          </div>
        </div>

        <div className={s.buttonsContainer}>
          <div className={s.buttons}>
            {filters.map((filter, index) => (
              <div className={s.buttonWrap} key={index + 'button'}>
                <button
                  className={`${s.btn} ${
                    activeFilter === filter ? s.active : null
                  }`}
                  onClick={() => {
                    setFilter(activeFilter === filter ? null : filter)
                    paginate(1)
                  }}
                >
                  <div className={s.filter}>{filter}</div>
                </button>
              </div>
            ))}
          </div>

          <Pagination
            itemsPerPage={jobsPerPage}
            totalItems={filteredList.length}
            paginate={paginate}
            currentPage={currentPage}
            styleProps={styleProps}
          />
        </div>

        <div className={s.circles}>
          {currentJobs.map((job, index) => (
            <>
              {job.is_active && (
                <div className={s.circleWrap} key={job.id + 'job'}>
                  <SlideIn duration={index * 0.3 + 0.3 + 's '}>
                    <Link
                      to={{
                        pathname: `/careers/${job.path}/${job.id}`,
                      }}
                      className={s.circle}
                    >
                      <span>{job.title}</span>
                      <img src={UpAndRight} alt="arrow" />
                    </Link>
                  </SlideIn>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Careers
