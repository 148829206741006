import s from './UploadCV.module.css'
import { useRef } from 'react'
import { DarkFile } from '../../../images'
import { LightFile } from '../../../images'

const UploadButton = props => {

  const textInput = useRef(null)

  function handleClick() {
    textInput.current.click()
  }


  return (
    <div className={`${ s.button}
                     ${props.buttonStyle === "light" ? s.light : '' }
                     ${props.variant === "large" ? s.large : ''}`} 
         onClick={handleClick}>
      <span>{props.text}</span>
      <input
        type="file"
        multiple
        onChange={(e) => props.handleUpload(e.target.files)}
        style={{ display: 'none' }}
        ref={textInput}
      />
      <img src={props.buttonStyle === "light" ? LightFile : DarkFile} width="24" height="24" alt="file" />
    </div>
  )
}

export default UploadButton