import s from './AboutPromo.module.css'
import { UpAndRight } from '../../../images'
import { Link } from 'react-scroll'
import styled, { keyframes } from 'styled-components'
import { fadeInRight } from 'react-animations'

const fadeInRightAnimation = keyframes`${fadeInRight}`

const FadeInDiv = styled.div`
  animation: 1.5s ${fadeInRightAnimation};
`

const AboutPromo = () => {
  return (
    <div className={s.container}>
      <div className={s.topWrap}>
        <div className={s.topInfo}>
          <div className={s.titles}>
            <span className={s.title1}>About</span>
            <span className={s.title2}>Milestep Team</span>
          </div>
        </div>
        <div className={s.bottomInfo}>
          <span className={s.text}>
            We might{' '}
            <span className={s.orange}>
              look like ninjas, unicorns, or cat fancier people on the outside.
            </span>
            Nevertheless, what unites us is our abiding commitment to writing
            code and the ever burning desire to transform the world, by turning
            high-tech dreams into reality
          </span>
        </div>
      </div>
      <div className={s.bottomWrap}>
        <FadeInDiv>
          <div className={s.links}>
            <Link to="ourBenefits" spy={true} smooth={true}>
              <div className={s.link}>
                <div className={s.linkText}>
                  <p>Values</p>
                </div>
                <div className={s.img}>
                  <img src={UpAndRight} alt="arrow" />
                </div>
              </div>
            </Link>

            <Link to="team" spy={true} smooth={true}>
              <div className={s.link}>
                <div className={s.linkText}>
                  <p>Team</p>
                </div>
                <div className={s.img}>
                  <img src={UpAndRight} alt="arrow" />
                </div>
              </div>
            </Link>

            <Link to="reviews" spy={true} smooth={true}>
              <div className={s.link}>
                <div className={s.linkText}>
                  <p>Reviews</p>
                </div>
                <div className={s.img}>
                  <img src={UpAndRight} alt="arrow" />
                </div>
              </div>
            </Link>
          </div>
        </FadeInDiv>
      </div>
    </div>
  )
}

export default AboutPromo
